import {Button, Card, Grid, PasswordInput, Text, TextInput, Title, useMantineTheme} from "@mantine/core";
import {useState} from "react";
import {useDocumentTitle} from "@mantine/hooks";
import {useModals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {useNavigate} from "react-router-dom";
import Connect from "../../lib/util/Connect";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import {setSession} from "../../lib/util/auth";
import {setUser} from "../../lib/util/user";
import {ErrorViewing} from "../../lib/util/ErrorViewing";


export const Login = () => {


    useDocumentTitle('HibritCard - HibritMedya');

    const theme = useMantineTheme();
    const [loading,setLoading] = useState(false);
    const modals = useModals();
    const navigate = useNavigate();

    const form = useForm({
        initialValues: {
            email: '',
            password:''
        },

        validate: {
            password: (value) => ( value  !== ''  ? null : 'Şifre girmelisiniz.'),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
        },
    });


    const setLogin = (values) => {


        setLoading(true);

        Connect().post('auth/login', {
            email: values.email,
            password: values.password
        }).then(({data}) => {


            SuccessViewing('Giriş Yapıldı', modals);

            setSession(data.result.token);
            setUser(data.result.payload);

            setTimeout(() => {
                navigate('/');
            }, 600);


        }).catch((e) => ErrorViewing(e,modals)).finally(() => setLoading(false));


    }


    return(<>

        <div className={"userPage"}>
            <Grid gutter={0} className={"h-100"}>
                <Grid.Col lg={12}>


                    <div className="table">
                        <div className="table-cell">
                            <Card className={"default-card login-card"} p={40} radius={"md"} shadow={"md"} style={{width:550,margin:'0 auto'}}>

                                <Title order={2}>Admin Giriş </Title>
                                <Text className={"text"}>Admin bilgierinizi girerek sisteme giriş yapabilirsiniz.</Text>

                                <form onSubmit={form.onSubmit((values) => setLogin(values))}>
                                    <TextInput
                                        label="E-posta"
                                        placeholder="E-posta"
                                        className={"form-input"}
                                        mt={13}
                                        {...form.getInputProps('email')}
                                    />

                                    <PasswordInput
                                        label="Şifre"
                                        placeholder="Şifreniz"
                                        className={"form-input"}
                                        mt={23}
                                        {...form.getInputProps('password')}
                                    />




                                    <Button mt={25} loading={loading} className={"active-btn"} fullWidth  color={"violet"} type="submit">Giriş Yap</Button>

                                </form>

                            </Card>
                        </div>
                    </div>



                </Grid.Col>
            </Grid>
        </div>

    </>)

}


export default Login;
