
export const priceFormat = (price) => {

    if (price){
        return Number(price).toFixed(2);
    }

    return '0.00';

}
