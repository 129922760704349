import dayjs from 'dayjs';
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import React, {forwardRef, useEffect, useState} from "react";
import {
    Badge,
    Button,
    Center,
    Container,
    Drawer,
    Grid,
    Group,
    LoadingOverlay,
    NumberInput,
    Pagination,
    Select,
    Table,
    Text,
    Title,
    Tooltip
} from "@mantine/core";
import {useForm} from "@mantine/form";
import Moment from "react-moment";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {priceFormat} from "../../lib/util/priceFormat";
import {IoCheckmarkCircle, IoCloseCircle, IoDownload, IoDownloadOutline, IoSearch} from "react-icons/io5";
import {IoIosClock} from "react-icons/io";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import _ from "lodash";
import {Link} from "react-router-dom";
import {getSession} from "../../lib/util/auth";
import {API_URL} from "../../lib/util/constants";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateInput } from '@mantine/dates';

dayjs.extend(customParseFormat);

export const SalePage = () => {


    useDocumentTitle('HibritCard - Satışlar');


    const [pageLoading, setPageLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [opened, {open, close}] = useDisclosure(false);
    const [payments, setPayments] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [searchValue, onSearchChange] = useState('');
    const [transferType, setTransferType] = useState('');
    const [status, setStatus] = useState('');
    const [users, setUser] = useState([]);
    const [extraTimePacket, setExtraTimePacket] = useState({
        premium: [],
        standart: [],
        test: []
    });
    const [transitionPacket, setTransitionPacket] = useState({
        premium: [],
        standart: [],
    });
    const [packetList, setPacketList] = useState([]);
    const modals = useModals();


    const form = useForm({
        initialValues: {
            packetId: '',
            userId: '',
            sale_type: 'ExtraTime',
            price: 0,
            checkout_date:new Date()
        },

        validate: {
            userId: (value) => (value !== '' ? null : 'Kullanıcı seçmelisiniz'),
            packetId: (value) => (value !== '' ? null : 'Paket seçmelisiniz'),
            price: (value) => (value > 0 ? null : 'Fiyat girmelisiniz'),
            checkout_date: (value) => (value  !== '' ? null : 'Tarih Seçmelisiniz'),
        },
    });


    useEffect(() => {

        getSales();
        getUsers();

    }, []);

    let statusType = (type) => {

        let status;
        let icon;
        let color;


        switch (type) {

            case "Failed":
                status = 'Ödeme Başarısız'
                icon = <IoCloseCircle color={"rgb(250, 82, 82)"} size={22}/>;
                color = 'rgb(250, 82, 82)';

                break;

            case "Waiting":
                status = 'İlerlemeyen Ödeme';
                icon = <IoIosClock color={"rgb(253, 126, 20)"} size={22}/>;
                color = 'rgb(253, 126, 20)';
                break;

            default:
                status = 'Başarılı'
                icon = <IoCheckmarkCircle color={"rgb(18, 184, 134)"} size={22}/>;
                color = 'rgb(18, 184, 134)';
        }

        return [status, icon, color];

    }


    const getSales = (page = 0, transfer_type = '', status = '') => {

        setPageLoading(true);

        let params = '';

        if (transfer_type !== '') {
            let filterText = `&transfer_type=${transfer_type}`;
            params = params + filterText;
        }

        if (status !== '') {

            let filterText = `&status=${status}`;
            params = params + filterText;
        }


        Connect().get(`payment/list?page=${page}${params}`).then(({data}) => {

            let _data = data.result;
            setTotalPage(_data.meta.lastPage);

            setPayments(_data.data);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => setPageLoading(false));

    }

    const rows = payments.map((element) => {


        return (
            <tr key={"table-" + element.id}>
                <td>#{element.id}</td>
                <td>
                    {element.member.username}
                </td>
                <td>
                    <div className={"table-name profile-name"}>
                        <b style={{paddingLeft: 0}}>{element.user_name}
                            <Text color={"gray"} size={"sm"} style={{display: 'block'}}>{element.email}</Text>
                        </b>
                    </div>
                </td>
                <td>
                    {element.user_phone.includes("+") ? element.user_phone : `+${element.user_phone}`}
                </td>
                <td>
                    <div className="badge-list">
                        <Badge className={"badge"} color={"indigo"}>
                            {element.member_payment_package_item.package_name}
                        </Badge>
                    </div>
                </td>
                <td>
                    {priceFormat(element.amount_try)}<span style={{marginLeft: 2}}>{element.currency}</span>
                </td>
                <td>
                    <Tooltip withArrow color={statusType(element.status)[2]} label={statusType(element.status)[0]}>
                        <div style={{width: 22}}>
                            {statusType(element.status)[1]}
                        </div>
                    </Tooltip>
                </td>
                <td>
                    {element.transfer_type === "CreditCard_PayTR" ? 'Kredi/Banka Kartı' : 'HAVALE/EFT'}
                </td>


                <td><Moment format={"DD/MM/YYYY HH:mm"}>{element?.checkout_date ?? element.created_at}</Moment></td>

            </tr>
        )
    });


    useEffect(() => {

        form.setFieldValue('packetId', '');
        setPacketList([]);

        let userId = form.values.userId;

        if (userId !== '') {

            let find = _.find(users, {
                value: userId
            });

            if (find) {
                getPackages(find.member_type, form.values.sale_type);
            }

        }

    }, [form.values.userId]);


    useEffect(() => {

        let userId = form.values.userId;

        let find = _.find(users, {
            value: userId
        });

        if (find) {
            getPackages(find.member_type, form.values.sale_type)
        }

    }, [form.values.sale_type]);


    useEffect(() => {

        let packet = _.find(packetList, {value: form.values.packetId});


        if (packet) {
            form.setFieldValue('price', Number(packet.price));
        }


    }, [form.values.packetId]);


    const getPackages = (member_type, sale_type) => {


        Connect().get('package/to/all').then(({data}) => {

            let _data = data.result;


            let packetData = [];


            const groupedData = _.groupBy(_data, 'package_type');

            let filterTransition = _.filter(groupedData['Transition'], {member_type: member_type});


            if (sale_type === 'Transition') {

                filterTransition.map((item) => {
                    packetData.push({label: item.package_name, value: item.package_uuid, price: item.price});
                });

            } else {

                groupedData['ExtraTime'].map((item) => {
                    packetData.push({label: item.package_name, value: item.package_uuid, price: item.price});
                });


            }

            setPacketList(packetData);

        }).catch((e) => ErrorViewing(e, modals));
    }


    const getUsers = () => {
        Connect().get('member/get/combo/list').then(({data}) => {

            let _data = [];


            data.result.map((item) => _data.push({
                value: item.id,
                label: item.email,
                fullname: item.firstname + ' ' + item.lastname,
                member_type: item.member_type
            }))

            setUser(_data);


        }).catch((e) => ErrorViewing(e, modals));
    }

    const setSale = (values) => {

        setLoading(true);

        Connect().post('payment/add/bank_transfer', {
            member_id: values.userId,
            package_uuid: values.packetId,
            price: values.price,
            checkout_date:values.checkout_date
        }).then(({data}) => {

            close();

            getSales();
            getUsers();

            form.setValues({
                packetId: '',
                userId: '',
            });

          setTimeout(()=>{
              SuccessViewing('Ödeme Eklendi',modals);
          },50);


        }).catch((e) => {

            ErrorViewing(e, modals);

        }).finally(() => setLoading(false))

    }

    const filterParameter = () => {


        let params = '';

        if (transferType !== '') {
            let filterText =  `&transfer_type=${transferType}`;
            params = params + filterText;
        }

        if (status !== '') {

            let filterText = `&status=${status}`;
            params = params + filterText;
        }

        return params;

    }


    const SelectItem = forwardRef(
        ({image, label, fullname, ...others}, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <div>
                        <Text size="sm">{fullname}</Text>
                        <Text size="xs" opacity={0.65}>
                            {label}
                        </Text>
                    </div>
                </Group>
            </div>
        )
    );


    return (<Container size={'100%'}>


        <Drawer opened={opened} onClose={close} position={'right'} title="Havale/EFT Ödeme Ekle">
            <form onSubmit={form.onSubmit((values) => setSale(values))}>
                <Grid>

                    <Grid.Col lg={12} mt={20}>
                        <span className={"label-title"}>Kullanıcı</span>
                        <Select
                            placeholder="Kullanıcı Seç"
                            searchable
                            itemComponent={SelectItem}
                            rightSection={<IoSearch color={"#bdbcbc"}/>}
                            onSearchChange={onSearchChange}
                            searchValue={searchValue}
                            nothingFound="Sonuç bulunamadı!"
                            filter={(value, item) =>
                                item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                item.fullname.toLowerCase().includes(value.toLowerCase().trim())
                            }

                            data={users}
                            {...form.getInputProps('userId')}
                        />
                    </Grid.Col>


                    <Grid.Col lg={12} mt={15}>
                        <span className={"label-title"}>Satış Tipi <small>(Süreyi Uzat / Paket Yükselt)</small></span>
                        <Select
                            placeholder="Satış Tipi Seç"
                            data={[
                                {label: 'Süreyi Uzat ', value: 'ExtraTime'},
                                {label: 'Paket Yükselt ', value: 'Transition'},

                            ]}
                            {...form.getInputProps('sale_type')}
                        />
                    </Grid.Col>


                    <Grid.Col lg={12} mt={15}>
                        <span className={"label-title"}>Paketler*</span>
                        <Select
                            placeholder="Paket Seç"
                            data={packetList}
                            {...form.getInputProps('packetId')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12} mt={15}>
                        <span className={"label-title"}>Fiyat*</span>
                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={15}
                            {...form.getInputProps('price')}
                        />

                    </Grid.Col>

                    <Grid.Col lg={12} mt={15}>
                        <span className={"label-title"}>Ödeme Tarihi*</span>
                        <DateInput
                          mb={15}
                          locale={"tr"}
                          className={"number-input"}
                          valueFormat="DD/MM/YYYY HH:mm"
                          {...form.getInputProps('checkout_date')}

                        />

                    </Grid.Col>

                </Grid>

                <Group mt={40} grow className={"bottom-head"}>

                    <Button loading={loading} className={"active-btn"}
                            type="submit">Ödemeyi Oluştur</Button>
                </Group>
            </form>
        </Drawer>

        <Group position={'right'}>
            <Button color={"violet"} onClick={open}>Havale/EFT Ödemesi Ekle</Button>
        </Group>


        <div className="table-card" style={{marginTop: 40}}>


            <div className="table-head">
                <Grid>
                    <Grid.Col lg={4}>
                        <Group>
                            <Title className={"table-title"} order={1}>Satışlar</Title>
                        </Group>
                        <Text className={"default-table-text"}>Satış listesi</Text>
                    </Grid.Col>


                    <Grid.Col lg={8}>
                        <Group position={'right'}>
                            <Select className={"mobile-w-100-input"} onChange={(val) => {

                                let valId = val !== null ? val : '';


                                getSales(activePage, valId, status);

                            }} placeholder={"Ödeme Tipi Seç"} clearable data={[
                                {
                                    value: 'CreditCard_PayTR',
                                    label: "Kredi/Banka Kartı"
                                },
                                {
                                    value: 'Bank_Transfer',
                                    label: "HAVALE/EFT"
                                }
                            ]}/>

                            <Select className={"mobile-w-100-input"} onChange={(val) => {

                                let valId = val !== null ? val : '';


                                getSales(activePage, status, valId);

                            }} color={"violet"} clearable placeholder={'Durum Seç'} size={"sm"} data={[
                                {
                                    value: 'Success',
                                    label: 'Başarılı Ödemeler'
                                },
                                {
                                    value: 'Waiting',
                                    label: 'İlerlemeyen Ödemeler'
                                },
                                {
                                    value: 'Failed',
                                    label: 'Başarısız Ödemeler'
                                }
                            ]}/>

                            <Button color={"teal"} leftIcon={<IoDownloadOutline/>} target={"_blank"} component={Link} to={`${API_URL}/payment/list/to/excel?token=${getSession()}${filterParameter()}&toexcel=true`}>Excel İndir</Button>


                        </Group>
                    </Grid.Col>


                </Grid>
            </div>


            <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                <Table className={"default-table"} striped highlightOnHover>
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>Kullanıcı Adı</th>
                        <th>
                            Ad Soyad
                        </th>
                        <th>Telefon</th>
                        <th>Paket</th>
                        <th>Tutar</th>
                        <th>Durum</th>
                        <th>Ödeme Tipi</th>
                        <th>Ödeme Tarihi</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>


            <div className="bottom-card">
                <Center>
                    <Pagination size={"sm"} total={totalPage} page={activePage} color={"violet"} onChange={(page) => {
                        setActivePage(page);
                        getSales(page);
                    }}/>
                </Center>
            </div>


        </div>

    </Container>)

}

export default SalePage;
