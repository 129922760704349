import {useDocumentTitle} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Container,
    Grid,
    Group,
    LoadingOverlay,
    Menu,
    Modal,
    NumberInput,
    rem,
    Select,
    Switch,
    Table,
    Tabs,
    Text,
    TextInput,
    Title,
    UnstyledButton
} from "@mantine/core";
import {useForm} from "@mantine/form";
import Moment from "react-moment";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import {MdDragIndicator} from "react-icons/md";
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import {FaRegEdit} from "react-icons/fa";
import {IoInformationCircleOutline, IoTrashOutline} from "react-icons/io5";


export const PacketsPage = () => {


    useDocumentTitle('HibritCard - Paketler');


    const [pageLoading, setPageLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [packetOpen, setPacketOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [packets, setPackage] = useState([]);
    const [packetExtraList, setPacketExtraList] = useState([]);
    const [packetId, setPacketId] = useState('');
    const [packetType, setPacketType] = useState('');
    const modals = useModals();


    const form = useForm({
        initialValues: {
            package_id: "",
            package_name: "",
            short_description: "",
            package_day: 0,
            card_price: 0,
            transit_member_type: null,
            status: true
        },

        validate: {
            package_id: (value) => (value !== '' ? null : 'Paket Tipi bırakılamaz'),
            package_name: (value) => (value !== '' ? null : 'Paket Adı boş bırakılamaz'),
            short_description: (value) => (value !== '' ? null : 'Kısa Açıklama boş bırakılamaz'),
            package_day: (value) => (value > 0 ? null : 'Uzatma Süresi girmelisiniz'),
            card_price: (value) => (value > 0 ? null : 'Fiyat boş bırakılamaz'),
        },
    });


    useEffect(() => {

        getPackage('ExtraTime');
        getPackage('Transition');

    }, []);


    const _setPackage = (values, type = 'Transition') => {

        setLoading(true);

        Connect().post('package/create', {
            package_name: values.package_name,
            package_day: values.package_day,
            price: values.card_price,
            member_type: values.package_id,
            package_type: type,
            short_description: values.short_description,
            transit_member_type: type === 'Transition' ? values.transit_member_type : null,
            status: values.status

        }).then(({data}) => {

            if (type === 'Transition') {
                getPackage('Transition');
            } else {
                getPackage('ExtraTime');

            }

            setOpened(false);
            setPacketOpen(false);

            SuccessViewing('Paket Oluşturuldu', modals);

            form.setValues({
                package_id: "",
                package_name: "",
                short_description: "",
                package_day: 0,
                card_price: 0,
                transit_member_type: null,
                status: true
            });


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));

    }


    const _editPackage = (values, type = 'Transition') => {


        setLoading(true);

        Connect().post(`package/edit/${packetId}`, {
            package_name: values.package_name,
            package_day: values.package_day,
            price: values.card_price,
            member_type: values.package_id,
            short_description: values.short_description,
            status: values.status

        }).then(({data}) => {


            if (type === 'Transition') {
                getPackage('Transition');
            } else {
                getPackage('ExtraTime');
            }

            setOpened(false);
            setPacketOpen(false);

            SuccessViewing('Düzenleme Kaydedildi', modals);

            form.setValues({
                package_id: "",
                package_name: "",
                short_description: "",
                package_day: 0,
                card_price: 0,
                transit_member_type: null,
                status: true
            });


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));

    }


    const getPackage = (type = '') => {

        setPageLoading(true);

        Connect().get(`package/list/${type}`).then(({data}) => {

            if (type === 'ExtraTime') {
                setPacketExtraList(data.result);
            } else {
                setPackage(data.result);
            }


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setPageLoading(false));

    }


    const removeItem = (id, type) => {

        Connect().get(`package/deleted/${id}`).then((data) => {

            SuccessViewing('Paket Silindi', modals);

        }).catch((e) => ErrorViewing(e, modals))
            .finally(() => {
                getPackage(type);
            });

    }

    const openConfirmModal = (id, type) => modals.openConfirmModal({
        title: '',
        centered: true,
        hideCloseButton: true,
        children: (<div className={"text-center"} style={{paddingBottom: 15, paddingTop: 13}}>
            <IoInformationCircleOutline color={"#abaaab"} size={73}/>
            <Text color={"gray"} style={{marginTop: 1, fontSize: '28px'}} weight={500}>Paketi Sil</Text>
            <Text color={"gray"} style={{marginTop: 3}} size="md">Paketi silmek istediğinize emin misiniz?</Text>

        </div>),
        cancelProps: {
            style: {backgroundColor: '#228be6', color: '#fff', borderColor: '#228be6'},
            className: "backButton"
        },
        confirmProps: {color: 'red', className: "confirmButton"},
        groupProps: {
            style: {
                "-webkit-box-pack": "start",
                "justify-content": "center",
                marginBottom: 10,
                marginTop: -5
            }
        },

        labels: {confirm: 'Sil', cancel: "Geri dön"},
        onCancel: () => console.log('Cancel'),
        onConfirm: () => removeItem(id, type),
    });

    useEffect(() => {

        form.setFieldValue('transit_member_type', null);

    }, [form.values.package_id]);


    const extraRows = packetExtraList.map((element, index) => {

        return (
            <Draggable key={`order-${element.id}`} index={index} draggableId={`order-${element.id}`}>
                {(provided) => (

                    <tr key={"table-" + element.id} ref={provided.innerRef} {...provided.draggableProps}>
                        <td>


                            <span style={{float: 'left'}}>
                                 #{element.id}
                            </span>
                        </td>
                        <td>{element?.package_name}</td>
                        <td>{element.member_type}</td>
                        <td>{element?.price} TL</td>
                        <td>
                            <Badge variant={"light"} className={"badge"}
                                   color={element.status ? 'teal' : 'gray'}>{element.status ? 'Aktif' : 'Pasif'}</Badge>
                        </td>
                        <td><Moment format={"DD/MM/YYYY HH:mm"}>{element.created_at}</Moment></td>
                        <td>
                            <Menu shadow="md" width={200}>
                                <Menu.Target>
                                    <UnstyledButton><HiOutlineDotsHorizontal/></UnstyledButton>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>İşlemler</Menu.Label>
                                    <Menu.Item onClick={() => {
                                        form.setValues({
                                            package_id: element.member_type,
                                            package_name: element.package_name,
                                            short_description: element.short_description,
                                            package_day: element.package_day,
                                            card_price: Number(element.price),
                                            transit_member_type: element.transit_member_type,
                                            status: element.status,
                                        });
                                        setPacketId(element.id);
                                        setOpened(true);
                                    }} icon={<FaRegEdit size={14}/>}>Düzenle</Menu.Item>

                                    <Menu.Item className={"remove-item"} color="red"
                                               onClick={() => openConfirmModal(element.id, 'ExtraTime')}
                                               icon={<IoTrashOutline style={{marginLeft: -1}} size={16}/>}>Paketi
                                        Sil</Menu.Item>

                                </Menu.Dropdown>
                            </Menu>
                        </td>
                    </tr>
                )}
            </Draggable>
        )
    });

    const rows = packets.map((element, index) => {

        return (
            <Draggable key={`order-${element.id}`} index={index} draggableId={`order-${element.id}`}>
                {(provided) => (

                    <tr key={"table-" + element.id} ref={provided.innerRef} {...provided.draggableProps}>
                        <td>


                            <span style={{float: 'left'}}>
                                 #{element.id}
                            </span>
                        </td>

                        <td><b style={{display: 'inline'}}>{element.member_type}</b> paketten => <b
                            style={{display: 'inline'}}>{element.transit_member_type}</b> paketine
                        </td>
                        <td>{element?.package_name}</td>
                        <td>{element?.price} TL</td>
                        <td>
                            <Badge variant={"light"} className={"badge"}
                                   color={element.status ? 'teal' : 'gray'}>{element.status ? 'Aktif' : 'Pasif'}</Badge>
                        </td>
                        <td><Moment format={"DD/MM/YYYY HH:mm"}>{element.created_at}</Moment></td>
                        <td>
                            <Menu shadow="md" width={200}>
                                <Menu.Target>
                                    <UnstyledButton><HiOutlineDotsHorizontal/></UnstyledButton>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>İşlemler</Menu.Label>
                                    <Menu.Item onClick={() => {
                                        form.setValues({
                                            package_id: element.member_type,
                                            package_name: element.package_name,
                                            short_description: element.short_description,
                                            package_day: element.package_day,
                                            card_price: Number(element.price),
                                            transit_member_type: element.transit_member_type,
                                            status: element.status,
                                        });
                                        setPacketId(element.id);
                                        setPacketOpen(true);
                                    }} icon={<FaRegEdit size={14}/>}>Düzenle</Menu.Item>

                                    <Menu.Item className={"remove-item"} color="red"
                                               onClick={() => openConfirmModal(element.id, 'Transition')}
                                               icon={<IoTrashOutline style={{marginLeft: -1}} size={16}/>}>Paketi
                                        Sil</Menu.Item>

                                </Menu.Dropdown>
                            </Menu>
                        </td>
                    </tr>
                )}
            </Draggable>
        )
    });


    return (<Container size={'100%'}>

        <Modal
            opened={packetOpen}
            centered
            title={packetId !== '' ? "Geçiş Paketini Düzenle" : "Geçiş Paketi Ekle"}
            size={800}
            radius={6}
            padding={"xl"}
            onClose={() => {
                setPacketOpen(false);
                form.setValues({
                    package_id: "",
                    package_name: "",
                    short_description: "",
                    package_day: 0,
                    card_price: 0,
                    transit_member_type: null,
                    status: true
                });
                setPacketId('');
            }}>


            <form onSubmit={form.onSubmit((values) => packetId !== '' ? _editPackage(values,'Transition') : _setPackage(values))}>

                <Grid>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Başlık*</span>
                        <TextInput
                            placeholder="Başlık"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('package_name')}
                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Kısa Açıklama*</span>
                        <TextInput
                            placeholder="Kısa Açıklama"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('short_description')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Paket Tipi*</span>
                        <Select
                            mt={10}
                            size={"md"}
                            placeholder="Paket Seç"
                            disabled={packetId !== ''}
                            data={[
                                {
                                    label: 'Standart',
                                    value: 'Standart'
                                },
                                {
                                    label: 'Test',
                                    value: 'Test'
                                }
                            ]}
                            {...form.getInputProps('package_id')}

                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Geçiş Paketi*</span>
                        <Select
                            mt={10}
                            size={"md"}
                            placeholder="Geçiş Paketi Seç"
                            disabled={packetId !== ''}

                            data={form.values.package_id === 'Standart' ? [
                                {
                                    label: 'Premium',
                                    value: 'Premium'
                                }
                            ] : [
                                {
                                    label: 'Premium',
                                    value: 'Premium'
                                },
                                {
                                    label: 'Standart',
                                    value: 'Standart'
                                }
                            ]}
                            {...form.getInputProps('transit_member_type')}

                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Fiyat*</span>

                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            precision={2}
                            placeholder={"0.00"}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={10}
                            {...form.getInputProps('card_price')}
                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Uzatma Süresi (Gün) *</span>

                        <NumberInput
                            hideControls
                            placeholder={"0"}
                            className={"number-input"}
                            size={"md"}
                            mb={10}
                            {...form.getInputProps('package_day')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <Switch
                            {...form.getInputProps('status', {type: 'checkbox'})}
                            label="Durum(Aktif/Pasif)"
                            color={"violet"}
                        />
                    </Grid.Col>
                </Grid>

                <Group mt={25} grow className={"bottom-head"}>

                    <Button loading={loading} className={"active-btn"}
                            type="submit">{packetId === '' ? 'Paket Ekle' : 'Düzenlemeyi Kaydet'}</Button>
                </Group>
            </form>

        </Modal>


        <Modal
            opened={opened}
            centered
            title={packetId !== '' ? "Uzatma Paketini Düzenle" : "Uzatma Paketi Ekle"}
            size={800}
            radius={6}
            padding={"xl"}
            onClose={() => {
                setOpened(false);
                form.setValues({
                    package_id: "",
                    package_name: "",
                    short_description: "",
                    package_day: 0,
                    card_price: 0,
                    transit_member_type: null,
                    status: true
                });
                setPacketId('');
            }}>


            <form
                onSubmit={form.onSubmit((values) => packetId !== '' ? _editPackage(values, 'ExtraTime') : _setPackage(values, 'ExtraTime'))}>

                <Grid>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Başlık*</span>
                        <TextInput
                            placeholder="Başlık "
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('package_name')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Kısa Açıklama*</span>
                        <TextInput
                            placeholder="Kısa Açıklama"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('short_description')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Paket Tipi*</span>
                        <Select
                            mt={10}
                            size={"md"}
                            disabled={packetId !== ''}

                            placeholder="Paket Seç"
                            data={[
                                {
                                    label: 'Premium',
                                    value: 'Premium'
                                },
                                {
                                    label: 'Standart',
                                    value: 'Standart'
                                }
                            ]}
                            {...form.getInputProps('package_id')}

                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Fiyat*</span>

                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            precision={2}
                            placeholder={"0.00"}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={10}
                            {...form.getInputProps('card_price')}
                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Uzatma Süresi (Gün) *</span>

                        <NumberInput
                            hideControls
                            placeholder={"0"}
                            className={"number-input"}
                            size={"md"}
                            mb={10}
                            {...form.getInputProps('package_day')}
                        />
                    </Grid.Col>


                    <Grid.Col lg={12}>
                        <Switch
                            {...form.getInputProps('status',{type:'checkbox'})}
                            label="Durum(Aktif/Pasif)"
                            color={"violet"}
                        />
                    </Grid.Col>


                </Grid>


                <Group mt={25} grow className={"bottom-head"}>

                    <Button loading={loading} className={"active-btn"}
                            type="submit">{packetId !== '' ? 'Düzenlemeyi Kaydet' : 'Paket Ekle'} </Button>
                </Group>
            </form>

        </Modal>


        <Tabs color={"violet"} defaultValue="gallery">
            <Tabs.List>
                <Tabs.Tab value="gallery">Uzatma Paketleri</Tabs.Tab>
                <Tabs.Tab value="messages">Geçiş Paketleri</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery" pt="xs">
                <div className="table-card" style={{marginTop: 20}}>

                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={8}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Uzatma Paketleri</Title>
                                </Group>
                                <Text className={"default-table-text"}></Text>
                            </Grid.Col>

                            <Grid.Col lg={4}>
                                <Group position={'right'}>
                                    <Button color={"violet"} onClick={() => {

                                        setOpened(true);
                                        form.setValues({
                                            package_id: "",
                                            package_name: "",
                                            short_description: "",
                                            package_day: 0,
                                            card_price: 0,
                                            transit_member_type: null,
                                            status: true
                                        });
                                    }}>Uzatma Paketi Ekle</Button>
                                </Group>
                            </Grid.Col>

                        </Grid>
                    </div>


                    <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                        <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                        <DragDropContext
                            onDragEnd={({destination, source}) => {


                            }}
                        >
                            <Table withColumnBorders sx={{minWidth: rem(420), '& tbody tr td': {borderBottom: 0}}}
                                   className={"default-table"}
                                   striped highlightOnHover>
                                <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        Başlık
                                    </th>
                                    <th>
                                        Paket Tipi
                                    </th>
                                    <th>
                                        Fiyat
                                    </th>
                                    <th>Durum</th>
                                    <th>Tarih</th>
                                    <th>İşlemler</th>
                                </tr>
                                </thead>
                                <Droppable droppableId="dnd-list" direction="vertical">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                        {extraRows}
                                        {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </Table>
                        </DragDropContext>

                    </div>


                </div>
            </Tabs.Panel>

            <Tabs.Panel value="messages" pt="xs">
                <div className="table-card" style={{marginTop: 20}}>

                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={8}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Geçiş Paketleri</Title>
                                </Group>
                                <Text className={"default-table-text"}></Text>
                            </Grid.Col>

                            <Grid.Col lg={4}>
                                <Group position={'right'}>
                                    <Button color={"violet"} onClick={() => {
                                        setPacketOpen(true);
                                        form.setValues({
                                            package_id: "Standart",
                                            package_name: "",
                                            short_description: "",
                                            package_day: 0,
                                            card_price: 0,
                                            transit_member_type: null,
                                            status: true
                                        })

                                    }}>Geçiş Paketi
                                        Ekle</Button>
                                </Group>
                            </Grid.Col>

                        </Grid>
                    </div>


                    <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                        <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                        <DragDropContext
                            onDragEnd={({destination, source}) => {


                            }}
                        >
                            <Table withColumnBorders sx={{minWidth: rem(420), '& tbody tr td': {borderBottom: 0}}}
                                   className={"default-table"}
                                   striped highlightOnHover>
                                <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        Paket Tipi
                                    </th>
                                    <th>
                                        Başlık
                                    </th>
                                    <th>
                                        Fiyat
                                    </th>
                                    <th>Durum</th>
                                    <th>Tarih</th>
                                    <th>İşlemler</th>
                                </tr>
                                </thead>
                                <Droppable droppableId="dnd-list" direction="vertical">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                        {rows}
                                        {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </Table>
                        </DragDropContext>

                    </div>


                </div>

            </Tabs.Panel>

        </Tabs>


    </Container>)

}

export default PacketsPage;
