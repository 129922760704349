import {useMediaQuery} from "@mantine/hooks";
import {Grid, Image, UnstyledButton,Group} from "@mantine/core";
import {FiMenu} from "react-icons/fi";
import {IoClose} from "react-icons/io5";
import {Link} from "react-router-dom";

export const Header = ({onOpen,onClose,open}) => {

    const matches = useMediaQuery('(min-width: 1200px)');


    if (!matches) {

        return (<header className={"header"}>
            <Grid>

                <Grid.Col span={6} mt={20} >
                  <Link to={'/'} onClick={onClose}>
                      <Image className={"filter-img"} src={'/img/white-logo.svg'} width={150} height={30} fit={"contain"}/>
                  </Link>
                </Grid.Col>

                <Grid.Col span={6}>
                   <Group position={'right'}>
                       <UnstyledButton className={"open-btn"} onClick={onOpen}>
                           {open ?  <IoClose size={17}/> :  <FiMenu/>}
                       </UnstyledButton>
                   </Group>
                </Grid.Col>

            </Grid>
        </header>)

    }

    return false;

}


export default Header;
