import Chart from "react-apexcharts";
import {useState} from "react";


export const HomeChart = (chartData = []) => {


    const [options, setOptions] = useState({

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        xaxis: {
            type: 'label',
            categories: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos","Eylül","Ekim","Kasım","Aralık"]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        },
    });

    const [series, setSeries] = useState([
        {
            name: 'Deneme',
            data: chartData.data[1].data
        },
        {
            name: 'Standart',
            data: chartData.data[2].data
        },
        {
            name: 'Premium',
            data: chartData.data[0].data
        }
    ]);

        return(
        <Chart
            options={options}
            series={series}
            type="area"
            height={350}
            width="100%"
        />
    )

}

export default HomeChart;
