import {useDocumentTitle} from "@mantine/hooks";
import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Center,
    Container,
    Grid,
    Group,
    Loader,
    Tabs,
    Text,
    Textarea,
    TextInput,
    Title,
    TransferList
} from "@mantine/core";
import {useForm} from "@mantine/form";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import _ from "lodash";
import JoditEditor from "jodit-react";


export const BulkPage = () => {


    useDocumentTitle('HibritCard - Toplu Sms/E-posta Gönderme');


    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [day, setDay] = useState(0);
    const [dataList, setData] = useState([[], []]);
    const [smsDataList, setSmsDataList] = useState([[], []]);
    const [dataExtraList, setDataExtraList] = useState([[], []]);
    const modals = useModals();
    const editor = useRef(null);


    const form = useForm({
        initialValues: {
            emailText: '',
            emailTitle: ''
        },

        validate: {
            emailTitle: (value) => (value  !== '' ? null : 'E-posta başlığı boş girilemez'),
            emailText: (value) => (value  !== '' ? null : 'E-posta içeriği boş girilemez'),
        },
    });

    const formSms = useForm({
        initialValues: {
            smsText: '',
        },

        validate: {
            smsText: (value) => (value  !== '' ? null : 'Sms içeriği boş girilemez'),
        },
    });


    useEffect(() => {

        getUsers();

    }, []);


    const getUsers = () => {
        Connect().get('member/get/combo/list').then(({data}) => {

            let _data = [];


            data.result.map((item) => _data.push({
                value: String(item.id),
                label: `${item.firstname} ${item.lastname}  (${item.email})`
            }));


            setData([_data, []]);
            setSmsDataList([_data, []]);
            setDataExtraList([_data, []]);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setPageLoading(false));

    }


    const setTimeAdd = (values, type = 'email') => {

        setLoading(true);

        let objData = {};

        if (type === 'email') {
            objData.members = _.map(dataList[1], 'value');
            objData.subject = values.emailTitle;
            objData.content = values.emailText;
        } else {
            objData.members = _.map(smsDataList[1], 'value')
            objData.content = values.smsText
        }

        Connect().post(type === 'email' ? 'member/send/content/email' : 'member/send/content/sms', objData).then(({data}) => {

            SuccessViewing(type === 'email' ? 'E-posta Gönderildi' : 'Sms Gönderildi', modals);

            form.setValues({
                emailText: '',
                emailTitle: ''
            });

            formSms.setValues({
                smsText: '',
            });

            setData(dataExtraList);
            setSmsDataList(dataExtraList);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setLoading(false);
        });

    }

    if (pageLoading) {
        return (<Center style={{height: 600}}>
            <Loader color={"violet"}/>
        </Center>)
    }


    return (<Container size={'100%'}>


        <Tabs defaultValue="gallery" color={"violet"}>
            <Tabs.List>
                <Tabs.Tab value="gallery">Toplu E-posta Gönder</Tabs.Tab>
                <Tabs.Tab value="messages">Toplu Sms Gönder</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery" pt="xs">

                <div className="table-card" style={{marginTop: 40}}>


                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={12}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Toplu E-posta Gönder</Title>
                                </Group>
                                <Text className={"default-table-text"}>E-posta detayları</Text>
                            </Grid.Col>
                        </Grid>
                    </div>

                    <div className="table-body" style={{padding: '20px 40px'}}>

                        <form onSubmit={form.onSubmit((values) => setTimeAdd(values))}>

                            <TransferList
                                value={dataList}
                                onChange={setData}
                                searchPlaceholder="Arama Yap..."
                                nothingFound="Sonuç Bulunamadı"
                                titles={['Seçilmeyen Kullanıcılar', 'Seçilen Kullanıcılar']}
                                breakpoint="sm"
                                mb={30}
                                filter={(query, item) =>
                                    item.label.toLowerCase().includes(query.toLowerCase().trim())
                                }
                            />


                            <TextInput mt={20} mb={30} placeholder={"Başlık Gir"}
                                       className={"form-input"}
                                       label={"Başlık"}
                                       {...form.getInputProps('emailTitle')}
                            />


                            <div className="text-editor">
                                <JoditEditor
                                    ref={editor}
                                    value={form.values.emailText}
                                    tabIndex={1}
                                    onBlur={val => form.setFieldValue('emailText', val)}
                                    onChange={newContent => {
                                    }}
                                />
                                {form.errors.emailText &&
                                    <div className="error-text-item">E-posta içeriği boş girilemez
                                    </div>
                                }
                            </div>


                            <Button loading={loading} type={"submit"} color={"violet"} fullWidth mt={30} mb={10}
                                    disabled={dataList[1].length === 0}>E-posta Gönder</Button>


                        </form>
                    </div>

                </div>


            </Tabs.Panel>
            <Tabs.Panel value="messages" pt="xs">

                <div className="table-card" style={{marginTop: 40}}>


                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={12}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Toplu Sms Gönder</Title>
                                </Group>
                                <Text className={"default-table-text"}>Sms detayları</Text>
                            </Grid.Col>
                        </Grid>
                    </div>

                    <div className="table-body" style={{padding: '20px 40px'}}>

                        <form onSubmit={formSms.onSubmit((values) => setTimeAdd(values, 'sms'))}>

                            <TransferList
                                value={smsDataList}
                                onChange={setSmsDataList}
                                searchPlaceholder="Arama Yap..."
                                nothingFound="Sonuç Bulunamadı"
                                titles={['Seçilmeyen Kullanıcılar', 'Seçilen Kullanıcılar']}
                                breakpoint="sm"
                                mb={30}
                                filter={(query, item) =>
                                    item.label.toLowerCase().includes(query.toLowerCase().trim())
                                }
                            />

                            <Textarea mt={20} className={"form-input"} label={"İçerik"}
                                      {...formSms.getInputProps('smsText')}
                                      placeholder={"İçerik Gir"}/>


                            <Button loading={loading} type={"submit"} color={"violet"} fullWidth mt={30} mb={10}
                                    disabled={smsDataList[1].length === 0}>Sms Gönder</Button>


                        </form>
                    </div>

                </div>


            </Tabs.Panel>

        </Tabs>


    </Container>)

}

export default BulkPage;
