import { useDocumentTitle } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import HomeCard from "../../lib/components/Card/Home";
import {
    Avatar,
    Badge,
    Center,
    Container,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    Select,
    Table,
    Text,
    Title
} from "@mantine/core";
import { IoBagHandleOutline, IoBagOutline, IoPricetagOutline, IoWalletOutline } from "react-icons/io5";
import { HomeChart } from "../../lib/components/Chart/home";
import Moment from 'react-moment';
import Connect from "../../lib/util/Connect";
import { ErrorViewing } from "../../lib/util/ErrorViewing";
import { useModals } from "@mantine/modals";
import { DateInput } from "@mantine/dates";


export const Home = () => {


    useDocumentTitle('HibritCard - Ana Sayfa');


    const [pageLoading, setPageLoading] = useState(true);
    const [loadChart, setLoadChart] = useState(false);
    const [statistic, setStatistic] = useState([
        {
            id: 1,
            title: 'TOPLAM',
            value: 0,
            icon: <IoWalletOutline/>,
            color: 'teal'
        },
        {
            id: 2,
            title: 'PREMIUM',
            value: 0,
            icon: <IoPricetagOutline/>,
            color: 'red'
        },
        {
            id: 3,
            title: 'STANDART',
            value: 0,
            icon: <IoBagOutline/>,
            color: 'violet'
        },
        {
            id: 4,
            title: 'DENEME',
            value: 0,
            icon: <IoBagHandleOutline/>,
            color: 'indigo'
        }
    ]);
    const [users, setUsers] = useState([]);
    const [chartData,setChartData] = useState([]);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [chartMonth,setChartMonth] = useState('');
    const modals = useModals();


    useEffect(() => {

        getHome();

    }, []);


    const getHome = (startDate = '',endDate = '', chartMonth = '') => {



        let params = '';

        let symbol = params !== '' ? '&' : '?';

        if (startDate !== '' && endDate !== '') {
            params = `${params}${symbol}start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
        }



        if (chartMonth !== ''){
            params = `${params}${symbol}chart_month=${chartMonth}`
        }




        Connect().get(`general/home${params}`).then(({data}) => {


            let _statistic = [...statistic];

            _statistic[0].value = data.result.count.total;
            _statistic[1].value = data.result.count.premium;
            _statistic[2].value = data.result.count.standart;
            _statistic[3].value = data.result.count.test;

            setStatistic(_statistic);

            let _chartData = data.result.chartData;

            setUsers(data.result.lastMembers);


            const isStandartInData = _chartData.find(item => item.name === "Standart") !== undefined;
            const isTestInData = _chartData.find(item => item.name === "Test") !== undefined;
            const isPremiumInData = _chartData.find(item => item.name === "Premium") !== undefined;

            if (!isStandartInData){
                _chartData.push({name: "Standart", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]})
            }
            if (!isTestInData){
                _chartData.push({name: "Test", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]})
            }

            if (!isPremiumInData){
                _chartData.push({name: "Premium", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]})
            }

            setChartData(_chartData);



            setPageLoading(false);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoadChart(false));

    }


    const formatDate = (date = '') => {

        const inputDate = new Date(date);


// Yıl, ay ve gün değerlerini alın
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başladığı için +1 ekliyoruz
        const day = inputDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }


    useEffect(() => {

        setLoadChart(true);

    }, [chartMonth]);


    const rows = users.map((element) => {


        return (
            <tr key={"table-" + element.id}>
                <td>
                    <div className={"table-name profile-name"}>

                        <Avatar src={element.image} radius="xl"/>


                        <b>{element.firstname} {element.lastname}
                            <Text color={"gray"} size={"sm"} style={{display: 'block'}}>{element.email}</Text>
                        </b>


                    </div>
                </td>
                <td>{element.uuid !== null ? element.uuid : 'Kartı Yok'}</td>
                <td>
                    <Badge variant={"light"} className={"badge"}
                           color={element.status ? 'teal' : 'gray'}>{element.status ? 'Aktif' : 'Pasif'}</Badge>

                </td>
                <td>
                    <Moment format={"YYYY-MM-DD HH:mm"}>{element.created_at}</Moment>
                </td>

            </tr>
        )
    });

    if (pageLoading){
        return (<Center style={{height:'calc(100vh - 130px)'}}>
            <Loader color={"violet"}/>
        </Center>)
    }


    return (<Container size={'100%'}>

        <Group position={'right'} mb={20}>
            <DateInput
              locale={"tr"}
                value={startDate}
                onChange={(val)=>{
                    let value = val !== null ? val : '';
                    setStartDate(value);
                }}
                clearable
                placeholder="Başlangıç Tarihi"
                className={"mobile-w-100-input"}
            />
            <DateInput
              locale={"tr"}
              value={endDate}
              onChange={(val) => {
                  let value = val !== null ? val : '';
                  setEndDate(value);
                  if (startDate !== '') {
                      getHome(startDate, value, chartMonth);
                  }
              }}
              clearable
              placeholder="Bitiş Tarihi"
              className={"mobile-w-100-input"}
            />
        </Group>


        <Grid cols={4} spacing="xl">
            {statistic.map((item, index) => (
             <Grid.Col lg={3} span={6} key={`home-${index}`}>
                 <HomeCard item={item} />
             </Grid.Col>
            ))}
        </Grid>

        <div className="table-card">

            <div className="table-head">
                <Grid>
                    <Grid.Col lg={8}>
                        <Group>
                            <Title className={"table-title"} order={1}>Hesap Özeti</Title>
                        </Group>
                        <Text className={"default-table-text"}>Yıllık Hesap Raporu</Text>
                    </Grid.Col>
                    <Grid.Col lg={4}>
                       <Group position={'right'}>
                           <Select className={"mobile-w-100-input"} onChange={(val) => {

                               let value = val !== null ? val : '';

                               getHome(startDate,endDate,value);
                               setChartMonth(value);


                           }} color={"violet"} clearable placeholder={'Ay Seç'} size={"sm"} data={[
                               {
                                   value: '1',
                                   label: 'Ocak'
                               },
                               {
                                   value: '2',
                                   label: 'Şubat'
                               },
                               {
                                   value: '3',
                                   label: 'Mart'
                               },
                               {
                                   value: '4',
                                   label: 'Nisan'
                               },
                               {
                                   value: '5',
                                   label: 'Mayıs'
                               },
                               {
                                   value: '6',
                                   label: 'Haziran'
                               },
                               {
                                   value: '7',
                                   label: 'Temmuz'
                               },
                               {
                                   value: '8',
                                   label: 'Ağustos'
                               },
                               {
                                   value: '9',
                                   label: 'Eylül'
                               },
                               {
                                   value: '10',
                                   label: 'Ekim'
                               },
                               {
                                   value: '11',
                                   label: 'Kasım'
                               },
                               {
                                   value: '12',
                                   label: 'Aralık'
                               },

                           ]}/>
                       </Group>


                    </Grid.Col>

                </Grid>
            </div>

           <div style={{minHeight:365}}>
               {!loadChart &&
                   <HomeChart data={chartData}/>
               }
           </div>


        </div>


        <Grid mb={20} gutter={30}>
            <Grid.Col lg={12}>
                <div className="table-card" style={{marginTop: 40}}>

                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={12}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Son Üye Olanlar</Title>
                                </Group>
                                <Text className={"default-table-text"}>Son Hesaplar</Text>
                            </Grid.Col>

                        </Grid>
                    </div>


                    <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                        <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                        <Table className={"default-table"} striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>
                                    Profil
                                </th>
                                <th>
                                    <div>
                                        Link
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Durum
                                    </div>
                                </th>
                                <th>Tarih</th>
                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </div>


                </div>

            </Grid.Col>

        </Grid>

    </Container>)

}

export default Home;
