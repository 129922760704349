import React from 'react';
import {FiLogOut} from 'react-icons/fi';
import {Avatar, Box, Group, Text, UnstyledButton, useMantineTheme} from '@mantine/core';
import {getUser} from "../util/user";
import {logOut} from "../util/auth";
import {useNavigate} from "react-router-dom";

export function User({open,data}) {
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const user = getUser();

    return (
        <Box className={"user-box"}>
            <UnstyledButton
                sx={{
                    display: 'block',
                    width: '100%',
                    padding: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                }}
            >
                <Group className={"user-area"}>
                    <Avatar
                        src={'/img/avatar-9.png'}
                        radius="xl"
                    />
                    {open &&
                        <Box sx={{flex: 1, paddingBottom: 5}}>
                            <Text size="sm" className={"title"} weight={600}>
                                {user?.fullname ?? 'Admin'}
                            </Text>
                            <Text color="dimmed" className={"text"} weight={400} size="xs">
                                {user?.email}
                            </Text>
                        </Box>
                    }

                    {open &&
                        <FiLogOut className={"logout"} color={"#667085"} onClick={() => {
                            logOut();
                            navigate('/login');

                        }} size={18}/>
                    }
                </Group>
            </UnstyledButton>
        </Box>
    );
}
