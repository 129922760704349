import {AppShell} from '@mantine/core';
import NavbarItem from "./Navbar";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Header from "../components/Header";

function Content(props) {

    const location = useLocation();
    const [open, setOpen] = useState(false);
    const user = props.user;




    useEffect(()=>{

        if (open){
            document.body.classList.add('none-scroll');
        } else{
            document.body.classList.remove('none-scroll');
        }

    },[open]);


    if (location.pathname === '/login') {

        return (<>
            {props.children}
        </>)

    } else{
        return (
            <AppShell
                padding="md"
                header={<Header open={open} onClose={() => setOpen(false)} onOpen={() => setOpen((o) => !o)}/>}
                navbar={<NavbarItem onClose={() => setOpen(false)}  user={user} open={open}/>}>
                {props.children}
            </AppShell>
        );
    }
}
export default Content;
