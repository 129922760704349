import {Button, Card, Container, Group, NumberInput, Tabs} from "@mantine/core";
import {IoPricetagOutline} from "react-icons/io5";
import {useForm} from "@mantine/form";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useEffect, useState} from "react";
import {useModals} from "@mantine/modals";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import Connect from "../../lib/util/Connect";
import {useDocumentTitle} from "@mantine/hooks";


export const PricePage = () => {


    useDocumentTitle('HibritCard - Kart Fiyatları')

    const [loading,setLoading] = useState(false);
    const modals = useModals();

    useEffect(()=>{

        getPrices();

    },[]);


    const form = useForm({
        initialValues: {
            standard_price: 0,
            standard_logo_price:0,
            standard_special_price:0,
            premium_price: 0,
            premium_logo_price:0,
            premium_special_price:0,
        },

        validate: {
            standard_price: (value) => ( value > 0 ? null : 'Geçersiz Fiyat'),
            standard_logo_price: (value) => ( value > 0 ? null : 'Geçersiz Fiyat'),
            standard_special_price: (value) => ( value > 0 ? null : 'Geçersiz Fiyat'),
            premium_price: (value) => ( value > 0 ? null : 'Geçersiz Fiyat'),
            premium_logo_price: (value) => ( value > 0 ? null : 'Geçersiz Fiyat'),
            premium_special_price: (value) => ( value > 0 ? null : 'Geçersiz Fiyat'),
        },
    });


    const getPrices = () =>{

        Connect().get('settings/edit').then(({data}) =>{


            let result = data.result;

            form.setValues({
                standard_price: Number(result.card_n_price),
                standard_logo_price:Number(result.card_n_price_logo),
                standard_special_price:Number(result.card_n_price_private),
                premium_price: Number(result.card_p_price),
                premium_logo_price:Number(result.card_p_price_logo),
                premium_special_price:Number(result.card_p_price_private),
            });

        }).catch((e) => ErrorViewing(e,modals));

    }


    const setPrice = (values) => {

        setLoading(true);

        Connect().post('settings/edit',{
            card_n_price: values.standard_price,
            card_n_price_logo:values.standard_logo_price,
            card_n_price_private: values.standard_special_price,
            card_p_price: values.premium_price,
            card_p_price_logo: values.premium_logo_price,
            card_p_price_private: values.premium_special_price
        }).then(({data}) => {

            SuccessViewing('Kart Fiyatları Güncellendi',modals);

        }).catch((e) => ErrorViewing(e,modals)).finally(() => setLoading(false));
    }


    return(<Container size={"100%"}>

        <Card shadow={"sm"} padding={"xl"}>
            <form onSubmit={form.onSubmit((values) => setPrice(values))}>

                <Tabs variant="default" color={"indigo"} defaultValue="standart">
                    <Tabs.List>
                        <Tabs.Tab value="standart" icon={<IoPricetagOutline size="0.8rem" />}> Standart Kart Fiyatları</Tabs.Tab>
                        <Tabs.Tab value="premium" icon={<IoPricetagOutline size="0.8rem" />}> Premium Kart Fiyatları</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="standart" pt="md">


                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            label="Hibritcard Fiyatı"
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={15}
                            {...form.getInputProps('standard_price')}
                        />

                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            label="Hibritcard Logolu Tasarım Fiyatı"
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={15}
                            {...form.getInputProps('standard_logo_price')}
                        />

                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            label="Hibritcard Ozel Tasarım Fiyatı"
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={25}
                            {...form.getInputProps('standard_special_price')}
                        />

                        <Group position={'left'}>
                            <Button loading={loading} type={"submit"} color={"violet"}>
                                Güncellemeyi Kaydet
                            </Button>
                        </Group>

                    </Tabs.Panel>

                    <Tabs.Panel value="premium" pt="md">



                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            label="Hibritcard Fiyatı (Premium)"
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={15}
                            {...form.getInputProps('premium_price')}
                        />

                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            label="Hibritcard Logolu Tasarım Fiyatı (Premium)"
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={15}
                            {...form.getInputProps('premium_logo_price')}
                        />

                        <NumberInput
                            hideControls
                            decimalSeparator=","
                            thousandsSeparator="."
                            label="Hibritcard Ozel Tasarım Fiyatı (Premium)"
                            precision={2}
                            className={"number-input"}
                            size={"md"}
                            step={0.5}
                            mb={25}
                            {...form.getInputProps('premium_special_price')}
                        />

                        <Group position={'left'}>
                            <Button loading={loading} type={"submit"} color={"violet"}>
                                Güncellemeyi Kaydet
                            </Button>
                        </Group>

                    </Tabs.Panel>


                </Tabs>

            </form>
        </Card>

    </Container>)

}


export default PricePage;
