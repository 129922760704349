import {useDocumentTitle} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Center,
    Container,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    NumberInput,
    Pagination,
    Table,
    Tabs,
    Text,
    Title,
    TransferList
} from "@mantine/core";
import {useForm} from "@mantine/form";
import Moment from "react-moment";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {priceFormat} from "../../lib/util/priceFormat";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import _ from "lodash";


export const ExtendPage = () => {


    useDocumentTitle('HibritCard - Süre Uzat');


    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [day, setDay] = useState(0);
    const [dataList, setData] = useState([[], []]);
    const [dataExtraList, setDataExtraList] = useState([[], []]);
    const modals = useModals();


    const form = useForm({
        initialValues: {
            day: 0,

        },

        validate: {
          //  day: (value) => (value > 0 ? null : 'Gün girmelisiniz'),
        },
    });


    useEffect(() => {

        getPastTime();
        getUsers();

    }, []);


    const getPastTime = (page = 0,load = true) => {

        setPageLoading(load);

        Connect().get(`member/extra/time?page=${page}`).then(({data}) => {

            let _data = data.result;
            setTotalPage(_data.meta.lastPage);

            setPayments(_data.data);

        }).catch((e) => ErrorViewing(e, modals))

    }

    const rows = payments.map((element) => {


        return (
            <tr key={"table-" + element.id}>
                <td>#{element.id}</td>
                <td>{element.member.username}</td>
                <td>{element.member.email}</td>
                <td>{element.in_day} Gün</td>
                <td><Moment format={"DD/MM/YYYY HH:mm"}>{element.created_at}</Moment></td>

            </tr>
        )
    });


    const getUsers = () => {
        Connect().get('member/get/combo/list').then(({data}) => {

            let _data = [];


            data.result.map((item) => _data.push({
                value: String(item.id),
                label: `${item.firstname} ${item.lastname}  (${item.email})`
            }));


            setData([_data, []]);
            setDataExtraList([_data, []]);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setTimeout(()=>{
                setPageLoading(false)
            },100);
        });

    }


    const setTimeAdd = (values) => {

        setLoading(true);

        Connect().post('member/extra/time', {
            members:_.map(dataList[1],'value'),
            in_day: values.day
        }).then(({data}) => {

            SuccessViewing('Süre Uzatma Tamamlandı', modals);

            form.setFieldValue('day',0);

            setData(dataExtraList);

            getPastTime(activePage,false)


        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setLoading(false);
        });

    }

    if (pageLoading) {
        return (<Center style={{height: 600}}>
            <Loader color={"violet"}/>
        </Center>)
    }


    return (<Container size={'100%'}>


        <Tabs defaultValue="gallery" color={"violet"}>
            <Tabs.List>
                <Tabs.Tab value="gallery">Süre Uzatma</Tabs.Tab>
                <Tabs.Tab value="messages">Süre Uzatma Geçmişi</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery" pt="xs">

                <div className="table-card" style={{marginTop: 40}}>


                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={12}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Süre Uzat</Title>
                                </Group>
                                <Text className={"default-table-text"}>Form detayları</Text>
                            </Grid.Col>
                        </Grid>
                    </div>

                    <div className="table-body" style={{padding: '20px 40px'}}>

                        <form onSubmit={form.onSubmit((values) => setTimeAdd(values))}>

                            <TransferList
                                value={dataList}
                                onChange={setData}
                                searchPlaceholder="Arama Yap..."
                                nothingFound="Sonuç Bulunamadı"
                                titles={['Seçilmeyen Kullanıcılar', 'Seçilen Kullanıcılar']}
                                breakpoint="sm"
                                filter={(query, item) =>
                                    item.label.toLowerCase().includes(query.toLowerCase().trim())
                                }
                            />

                            <NumberInput className={"form-input"} mt={20} placeholder={"0"}
                                         label={"Süre(Gün)"}
                                         {...form.getInputProps('day')}
                            />


                            <Button loading={loading} type={"submit"} color={"violet"} fullWidth mt={30} mb={10}
                                    disabled={dataList[1].length === 0}>Süreyi Uzat</Button>


                        </form>
                    </div>

                </div>


            </Tabs.Panel>
            <Tabs.Panel value="messages" pt="xs">
                <div className="table-card" style={{marginTop: 40}}>


                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={12}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Süre Uzatma Geçmiş</Title>
                                </Group>
                                <Text className={"default-table-text"}>Geçmiş Listesi</Text>
                            </Grid.Col>
                        </Grid>
                    </div>


                    <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                        <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                        <Table className={"default-table"} striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Kullanıcı Adı</th>
                                <th>Email</th>
                                <th>Uzatılan Gün</th>
                                <th>Tarih</th>
                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </div>


                    <div className="bottom-card">
                        <Center>
                            <Pagination size={"sm"} total={totalPage} page={activePage} color={"violet"}
                                        onChange={(page) => {
                                            setActivePage(page);
                                            getPastTime(page,false);
                                        }}/>
                        </Center>
                    </div>


                </div>
            </Tabs.Panel>
        </Tabs>


    </Container>)

}

export default ExtendPage;
