import Cookies from 'js-cookie'

export const setSession = (token) => {
    return Cookies.set('__token',token);
}


export const getSession = () => {
    return Cookies.get('__token')
}


export const logOut = () => {
    Cookies.remove('__token');
    Cookies.remove('__admin');

}
