import React, {useState} from 'react';
import {MediaQuery, Navbar, NavLink, ScrollArea, UnstyledButton,Image} from '@mantine/core';
import {User} from './_user';
import {Link} from "react-router-dom";
import {
    IoHome,
    IoMenu,
    IoUser,
    IoSettings,
    IoLink,
    IoNotifications,
    IoCard,
    IoPricetag,
    IoSearch,
    IoPerson,
    IoPersonOutline,
    IoList,
    IoPricetagOutline,
    IoListOutline,
    IoMailOutline,
    IoTime,
    IoTimeOutline,
    IoSend, IoSendOutline
} from "react-icons/io5";
import {GoPackage} from "react-icons/go";
import {MdOutlinePayments} from "react-icons/md";

function NavbarItem({user, open, onClose}) {

    const [active, setActive] = useState(0);
    const [navLink, setNavLink] = useState([
        {
            id: 1,
            title: 'Anasayfa',
            icon: <IoHome size={21}/>,
            link: '/'
        },
        {
            id: 2,
            title: 'Link Üret',
            icon: <IoLink size={21}/>,
            link: '/link/add'
        },
        {
            id: 3,
            title: 'Kullanıcılar',
            icon: <IoPersonOutline size={21}/>,
            link: '/users'
        },

        /* {
            id: 4,
            title: 'Bildirimler',
            icon: <IoNotifications size={21}/>,
            link: '/notices'
        },*/

        {
            id: 4,
            title: 'Taslaklar',
            icon: <IoMailOutline size={21}/>,
            link: '/emails'
        },

        /*    {
                id: 6,
                title: 'Kart Fiyatları',
                icon: <IoPricetag size={21}/>,
                link: '/prices'
            },*/


        {
            id: 5,
            title: 'Satışlar',
            icon: <MdOutlinePayments size={21}/>,
            link: '/sales'
        },

        {
            id: 6,
            title: 'Paketler',
            icon: <IoList size={21}/>,
            link: '/packets'
        },


        {
            id: 7,
            title: 'Süre Uzat',
            icon: <IoTimeOutline size={21}/>,
            link: '/extend-time'
        },

        {
            id: 8,
            title: 'Toplu Sms/E-posta Gönderme',
            icon: <IoSendOutline size={21}/>,
            link: '/bulk/send'
        }

    ]);

    const [openMenu, setOpenMenu] = useState(true);





    return (
        <Navbar height={"calc(100% - 55px)"} p="xs" width={{base: openMenu ? 280 : 82}}
                className={!openMenu ? "small-menu" : open ? "active" : ""}>
            <MediaQuery
                query="(max-width: 1200px)"
                styles={{display: 'none'}}
            >
                <div>
                    <div id="logo" className={!openMenu ? 'active-logo' : ''}>
                        <Link to={'/'}>
                            <Image src={"/img/white-logo.svg"} width={120}/>
                        </Link>
                    </div>
                    <UnstyledButton className={"menu-toggle"} onClick={() => setOpenMenu((o) => !o)}>
                        <IoMenu size={22} color={"#344054"}/>
                    </UnstyledButton>
                </div>

            </MediaQuery>



            <Navbar.Section component={ScrollArea} grow mt={24} className={"sidebar-menu"}>
                {navLink.map((item, index) => (
                    <NavLink
                        label={item.title}
                        component={Link}
                        key={`nav-${index}`}
                        active={index === active}
                        to={item.link}
                        icon={item.icon}
                        onClick={() => {
                            setActive(index);
                            onClose();
                        }}
                    />
                ))}
            </Navbar.Section>

            <Navbar.Section>


                <User open={openMenu} data={user}/>
            </Navbar.Section>
        </Navbar>
    );
}


export default NavbarItem;
