import {useDocumentTitle} from "@mantine/hooks";
import React, {useEffect, useRef, useState} from "react";
import {
    Alert,
    Button,
    Center,
    Container,
    Text,
    Grid,
    Loader,
    Table,
    Tabs,
    Textarea,
    Title,
    Divider
} from "@mantine/core";
import {useForm} from "@mantine/form";
import JoditEditor from "jodit-react";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import _ from "lodash";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import parse from 'html-react-parser';

export const EmailPage = () => {


    useDocumentTitle('HibritCard - Taslaklar');


    const [pageLoading, setPageLoading] = useState(true);
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [textContent, setTextContent] = useState("");
    const editor = useRef(null);
    const modals = useModals();
    const [emailList, setEmailList] = useState([]);
    const [smsList, setSmsList] = useState([]);
    const [parameters, setParameters] = useState([]);

    const form = useForm({
        initialValues: {
            title: '',
        },

        validate: {
            content: (value) => (value !== '' ? null : 'Mesaj boş bırakılamaz'),
        },
    });

    useEffect(() => {

        getDrafts();
        //  getDraftItem('CODE_SMS');

    }, []);


    const getParamType = (id = '') => {

        let param = [];

        switch (id) {

            case "CODE_SMS":
                param.push(["{{code}}","SMS ONAY KODU"]);
                param.push(["{{uuid}}","UUID LİNKİ"]);
                break;

            case "CODE_EMAIL":
                param.push(["{{code}}","EMAIL ONAY KODU"]);
                param.push(["{{uuid}}","UUID LİNKİ"]);
                break;

            case "RESET_PASS_SMS":
                param.push(["{{firstname}}","AD"]);
                param.push(["{{lastname}}","SOYAD"]);
                param.push(["{{uuid}}","UUID LİNKİ"]);
                break;

            case "RESET_PASS_SMS_USER":
                param.push(["{{firstname}}","AD"]);
                param.push(["{{lastname}}","SOYAD"]);
                param.push(["{{uuid}}","UUID LİNKİ"]);
                break;



            case "RESET_PASS_EMAIL_USER":
                param.push(["{{firstname}}","AD"]);
                param.push(["{{lastname}}","SOYAD"]);
                param.push(["{{uuid}}","UUID LİNKİ"]);
                break;

            case "RESET_PASS_EMAIL":
                param.push(["{{firstname}}","AD"]);
                param.push(["{{lastname}}","SOYAD"]);
                param.push(["{{uuid}}","UUID LİNKİ"]);
                break;

            case "PAY_RESULT_EXTRATIME_EMAIL":
                param.push(["{{package_name}}","Paket Adı"]);
                param.push(["{{exp_date}}","Satın Alınmadan Önce Hesap Bitiş Tarihi"]);
                param.push(["{{new_exp_date}}","Satın Alındıktan Sonra Hesap Bitiş Tarihi"]);
                break;

            case "PAY_RESULT_TRANSITION_EMAIL":
                param.push(["{{package_name}}","Paket Adı"]);
                param.push(["{{exp_date}}","Hesap Bitiş Tarihi"]);
                param.push(["{{new_exp_date}}","Yeni Hesap Bitiş Tarihi"]);
                break;



            case "PAY_RESULT_EXTRATIME_SMS":
                param.push(["{{package_name}}","Paket Adı"]);
                param.push(["{{exp_date}}","Satın Alınmadan Önce Hesap Bitiş Tarihi"]);
                param.push(["{{new_exp_date}}","Satın Alındıktan Sonra Hesap Bitiş Tarihi"]);
                break;


            case "PAY_RESULT_TRANSITION_SMS":
                param.push(["{{package_name}}","Paket Adı"]);
                param.push(["{{exp_date}}","Hesap Bitiş Tarihi"]);
                param.push(["{{new_exp_date}}","Yeni Hesap Bitiş Tarihi"]);
                break;


            case "CUSTOMER_FORM":
                param.push(["{{item_fullname}}","AD SOYAD"]);
                param.push(["{{item_phone}}","TELEFON"]);
                param.push(["{{item_email}}","E-POSTA"]);
                param.push(["{{item_message}}","MESAJ"]);
                break;





        }

        return param;
    }


    const getDrafts = () => {
        Connect().get('draft/list').then(({data}) => {

            const groupData = _.groupBy(data.result, 'draft_type');


            let params = [];

            data.result.map((item) => {
                params[item.draft_id] = getParamType(item.draft_id)
            });


            setParameters(params);

            if (groupData['SMS'] !== undefined) {
                setSmsList(groupData['SMS']);
            }

            if (groupData['Email'] !== undefined) {


                groupData['Email'].map((item) => {
                    item.draft_content = parse(item.draft_content)
                });


                setEmailList(groupData['Email']);
            }


        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setPageLoading(false)
        });
    }

    const getDraftItem = (id) => {
        Connect().get(`draft/get/${id}`).then(({data}) => {

            console.log('data', data.result);

        }).catch((e) => ErrorViewing(e, modals));
    }


    const setDraftItem = (id, value) => {

        setLoading(true);

        Connect().post(`draft/edit/${id}`, {
            draft_content: value
        }).then(({data}) => {

            SuccessViewing('Güncelleme Tamamlandı', modals);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));

    }


    const smsValueUpdate = (id, value) => {

        const _smsList = [...smsList];

        let find = _.find(_smsList, {
            draft_id: id
        });

        if (find) {
            find.draft_content = value
        }

        setSmsList(_smsList);

    }

    const emailValueUpdate = (id, value) => {

        const _emailList = [...emailList];

        let find = _.find(_emailList, {
            draft_id: id
        });

        if (find) {
            find.draft_content = value
        }

        setEmailList(_emailList);

    }

    if (pageLoading) {
        return (<Center style={{height: 600}}>
            <Loader color={"violet"}/>
        </Center>)
    }


    return (<Container size={'100%'}>


        <Grid>
            <Grid.Col lg={12}>
                <div className="table-card" style={{marginTop: 40}}>


                    <Tabs color="violet" defaultValue="email" pt={"md"}>
                        <Tabs.List>
                            <Tabs.Tab value="email">Email Kalıplar</Tabs.Tab>
                            <Tabs.Tab value="sms">Sms Kalıpları</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="email" pt={"xs"}>

                            <Tabs color="indigo" orientation="vertical" defaultValue="email_0">
                                <Tabs.List>
                                    {emailList.map((item, index) => (<Tabs.Tab key={`email-${index}`}
                                                                               value={`email_${index}`}>{item.draft_title}</Tabs.Tab>))}
                                </Tabs.List>

                                {emailList.map((item, index) => (

                                    <Tabs.Panel value={`email_${index}`} pl="xs">
                                        <div className="table-body text-editor" style={{padding: '20px 40px'}}>

                                            <Text>Parametreler</Text>
                                            <Divider variant={"dashed"} mt={10} mb={3} />
                                            <Table  mb={25} className={"table-pd-small"} fontSize={"xs"}>
                                                <tbody>
                                                {parameters[item.draft_id].map((item,index) => (
                                                    <tr key={`${item.draft_id}-${index}`}>
                                                        <td>{item[0]}</td>
                                                        <td>{item[1]}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>

                                            <form onSubmit={form.onSubmit((values) => console.log(values))}>
                                                <JoditEditor
                                                    ref={editor}
                                                    value={item.draft_content}
                                                    tabIndex={1}
                                                    onBlur={val => emailValueUpdate(item.draft_id, val)}
                                                    onChange={newContent => {
                                                    }}
                                                />
                                                <Button onClick={() => setDraftItem(item.draft_id, item.draft_content)}
                                                        loading={loading} type="submit" color={"violet"}
                                                        mt={20}>Kaydet</Button>
                                            </form>

                                        </div>

                                    </Tabs.Panel>
                                ))}
                            </Tabs>


                        </Tabs.Panel>

                        <Tabs.Panel value="sms" pt="xs">
                            <Tabs color="violet" orientation="vertical" defaultValue="sms_0">
                                <Tabs.List>
                                    {smsList.map((item, index) => (<Tabs.Tab key={`sms-${index}`}
                                                                             value={`sms_${index}`}>{item.draft_title}</Tabs.Tab>))}
                                </Tabs.List>

                                {smsList.map((item, index) => (
                                    <Tabs.Panel value={`sms_${index}`} pl="xs">


                                        <div className="table-body text-editor" style={{padding: '20px 40px'}}>
                                            <Text>Parametreler</Text>
                                            <Divider variant={"dashed"} mt={10} mb={3} />
                                            <Table  className={"table-pd-small"} fontSize={"xs"}>
                                                <tbody>
                                                {parameters[item.draft_id].map((item,index) => (
                                                    <tr key={`${item.draft_id}-${index}`}>
                                                        <td>{item[0]}</td>
                                                        <td>{item[1]}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>


                                            <Textarea mt={20} className={"form-input"} label={"İçerik"}
                                                      value={item.draft_content}
                                                      onChange={(event) => smsValueUpdate(item.draft_id, event.currentTarget.value)}
                                                      placeholder={"İçerik Gir"}/>
                                            <Button type="submit" color={"violet"} mt={20}
                                                    onClick={() => setDraftItem(item.draft_id, item.draft_content)}
                                                    loading={loading}>Kaydet</Button>
                                        </div>
                                    </Tabs.Panel>
                                ))}

                            </Tabs>


                        </Tabs.Panel>


                    </Tabs>

                </div>
            </Grid.Col>
        </Grid>

    </Container>)

}

export default EmailPage;
