import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import {
    Avatar, Badge,
    Button, Center,
    Collapse,
    Container,
    CopyButton,
    Grid,
    Group,
    LoadingOverlay, Modal, Pagination, Table,
    Text, Textarea,
    TextInput,
    Title
} from "@mantine/core";
import {useForm} from "@mantine/form";
import Moment from "react-moment";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {SuccessViewing} from "../../lib/util/SuccessViewing";


export const NoticePage = () => {


    useDocumentTitle('HibritCard - Bildirimler');


    const [pageLoading, setPageLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [loading,setLoading] = useState(false);
    const [notice,setNotice] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const modals = useModals();


    const form = useForm({
        initialValues: {
            title: '',
            content: '',
        },

        validate: {
            content: (value) => ( value !== '' ? null : 'Mesaj boş bırakılamaz'),
        },
    });


    useEffect(()=>{

        getNotice();

    },[]);


    const _setNotice = (values) => {

        setLoading(true);

        Connect().post('notification/create',values).then(({data}) =>{

            getNotice();
            setOpened(false);

            SuccessViewing('Bildirim Eklendi',modals);

            form.setValues({
                title: '',
                content: '',
            })

        }).catch((e) => ErrorViewing(e,modals)).finally(()=> setLoading(false));

    }

    const getNotice = () => {

        setPageLoading(true);

        Connect().get('notification/list').then(({data}) =>{

         setNotice(data.result);

        }).catch((e) => ErrorViewing(e,modals)).finally(()=> setPageLoading(false));

    }

    const rows = notice.map((element) => {


        return (
            <tr key={"table-" + element.id}>
                <td>#{element.id}</td>
                <td>
                    {element.title}
                </td>
                <td>
                    <div style={{width:600,maxWidth:'100%'}}>
                        {element.content}
                    </div>
                </td>


               <td><Moment format={"YYYY-MM-DD HH:mm"}>{element.created_at}</Moment></td>

            </tr>
        )
    });


    return (<Container size={'100%'}>


        <Modal
            opened={opened}
            centered
            title={"Bildirim Ekle"}
            size={640}
            radius={6}
            padding={"xl"}
            onClose={() => {
                setOpened(false);
                form.setValues({
                    title: '',
                    phone:'',
                    address:'',
                    tags: []
                })
            }}>


            <form onSubmit={form.onSubmit((values) => _setNotice(values))}>

                <Grid>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Başlık*</span>
                        <TextInput
                            placeholder="Başlık Gir"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('title')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12} mt={5}>
                        <span className={"label-title"}>Mesaj*</span>
                        <Textarea
                            placeholder="Mesaj Gir"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('content')}
                        />
                    </Grid.Col>
                </Grid>


                <Group mt={25} grow className={"bottom-head"}>

                    <Button loading={loading} className={"active-btn"}
                            type="submit">Bildirim Ekle</Button>
                </Group>
            </form>

        </Modal>



        <div className="table-card" style={{marginTop: 40}}>

            <div className="table-head">
                <Grid>
                    <Grid.Col lg={8}>
                        <Group>
                            <Title className={"table-title"} order={1}>Bildirimler</Title>
                        </Group>
                        <Text className={"default-table-text"}></Text>
                    </Grid.Col>

                    <Grid.Col lg={4}>
                       <Group position={'right'}>
                           <Button color={"violet"} onClick={() => setOpened(true)}>Bildirim Ekle</Button>
                       </Group>
                    </Grid.Col>

                </Grid>
            </div>


            <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                <Table className={"default-table"} striped highlightOnHover>
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Başlık
                        </th>
                        <th>
                            <div>
                                Mesaj
                            </div>
                        </th>
                        <th>Tarih</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>


        </div>

    </Container>)

}

export default NoticePage;
