import {useDocumentTitle} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import {
    Avatar,
    Badge,
    Button,
    Center,
    Checkbox,
    Collapse,
    Container,
    CopyButton,
    Drawer,
    Grid,
    Group, HoverCard,
    LoadingOverlay,
    Menu,
    Modal,
    Pagination,
    Select,
    Switch,
    Table,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    Tooltip,
    UnstyledButton
} from "@mantine/core";
import {useForm} from "@mantine/form";
import Moment from "react-moment";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {IoCopy, IoInformationCircleOutline, IoRefresh, IoSearch, IoTrashOutline} from "react-icons/io5";
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import {TbClock, TbPremiumRights} from "react-icons/tb";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import {FaRegEdit, FaRegEnvelope} from "react-icons/fa";
import {DateInput} from '@mantine/dates';
import {HIBRIT_CARD_PANEL} from "../../lib/util/constants";
import moment from "moment";
import {getFilter, getOrder, setFilter, setOrder} from "../../lib/util/user";
import PhoneInput from "react-phone-input-2";


export const UserPage = () => {


    useDocumentTitle('HibritCard - Kullanıcılar');

    const modals = useModals();
    const [pageLoading, setPageLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);

    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [search, setSearch] = useState('');
    const [openMenu, setOpenMenu] = useState(false);
    const [codeList, setCodeList] = useState([]);
    const [userId, setUserId] = useState('');
    const [premiumLoading, setPremiumLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [userFormOpen, setUserFormOpen] = useState(false);
    const [filterId, setFilterId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [passList, setPassList] = useState([]);
    const [activePassPage, setActivePassPage] = useState(1);
    const [totalPassPage, setTotalPassPage] = useState(1);
    const [passId, setPassId] = useState('');
    const [searchType,setSearchType] = useState("");

    useEffect(() => {

        setFilterId(getFilter());
        setOrderId(getOrder());
        getUsers(activePage, search, getFilter(), getOrder(), true);

    }, []);


    const rePasswordForm = useForm({
        initialValues: {
            emailStatus: false,
            phoneStatus: false,
            id: '',
            email: '',
            phone: '+90'
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz email adresi'),
            phone: (value) => (rePasswordForm.values.phoneStatus ? value !== '' ? null : 'Telefon Numarası boş bırakılamaz' : null),
        },
    });


    const form = useForm({
        initialValues: {
            username: "",
            firstname: "",
            lastname: "",
            phone_code: "",
            gender: "",
            birth_date: new Date(),
            status: true,
            uuid: "",
            member_type: ""
        },

        validate: {
            username: (value) => (value !== '' ? null : 'Kullanıcı Adı boş bırakılamaz'),
            firstname: (value) => (value !== '' ? null : 'Ad boş bırakılamaz'),
            lastname: (value) => (value !== '' ? null : 'Soyad boş bırakılamaz'),
            //    phone_code: (value) => (value !== '' ? null : 'Telefon Numarası boş bırakılamaz'),
            //  gender: (value) => (value !== '' ? null : 'Cinsiyet seçmelisiniz'),
            birth_date: (value) => (value !== '' ? null : 'Doğum Tarihi seçmelisiniz'),
            uuid: (value) => (value !== '' ? null : 'UUID boş bırakılamaz'),
            member_type: (value) => (value !== '' ? null : 'Üyelik Tipi seçmelisiniz'),
        },
    });


    const getUsers = (page = 1, search = '', filter = '',  order = '', load = true) => {


        let param = '';

        if (search !== '') {
            param = ` &search=${search}`
        }


        if (filter !== '' && filter !== null) {

            let filterText = `&filter_selection=${filter}`;
            param = param + filterText;
        }

        if (order !== '') {

            let orderText = `&filter_order_by=${order}`;
            param = param + orderText;
        }

        if (searchType !== ''){
            let orderText = `&filter_param=${searchType}`;
            param = param + orderText;

        }


        setPageLoading(load);

        Connect().get(`member/get?page=${page}${param !== null ? param : ''}`).then(({data}) => {


            setTotalPage(data.result.meta.lastPage);
            setUsers(data.result.data);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setPageLoading(false));

    }

    const getMember = (id) => {

        setUserId(id);

        Connect().get(`member/list/premium/code/${id}`).then(({data}) => {


            setCodeList(data.result);


        }).catch((e) => {

        });

    }

    const setPremium = () => {

        setPremiumLoading(true);

        Connect().get(`member/create/premium/code/${userId}`).then(({data}) => {

            getMember(userId);

            SuccessViewing('Premium Kod Oluşturuldu', modals);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => setPremiumLoading(false));

    }

    const _setUser = (values) => {

        setEditLoading(true);

        Connect().post(`member/edit/${userId}`, values).then(({data}) => {

            SuccessViewing("Düzenleme Kaydedildi", modals);

            getUsers(activePage, search, filterId, orderId, false);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => setEditLoading(false));

    }

    const statusType = (type) => {
        let color = 'indigo';

        switch (type) {
            case "Test":
                color = "blue";
                break;
            case "Standart":
                color = "orange";
                break;
            case "Premium":
                color = "violet";
                break;
        }

        return color;

    }

    function gunFarkiHesapla(tarih) {
        const baslangicTarihi = moment();
        const bitisTarihi = moment(tarih);

        const gunFarki = bitisTarihi.diff(baslangicTarihi, 'days');

        return gunFarki;
    }


    const removeItem = (id) => {

        Connect().get(`member/deleted/${id}`).then((data) => {


            SuccessViewing('Kullanıcı Silindi', modals);

        }).catch((e) => ErrorViewing(e, modals))
            .finally(() => {
                getUsers(activePage, search, false);
            });

    }


    const openConfirmModal = (id) => modals.openConfirmModal({
        title: '',
        centered: true,
        hideCloseButton: true,
        children: (<div className={"text-center"} style={{paddingBottom: 15, paddingTop: 13}}>
            <IoInformationCircleOutline color={"#abaaab"} size={73}/>
            <Text color={"gray"} style={{marginTop: 1, fontSize: '28px'}} weight={500}>Kullanıcıyı Sil</Text>
            <Text color={"gray"} style={{marginTop: 3}} size="md">Kullanıcıyı silmek istediğinize emin misiniz?</Text>

        </div>),
        cancelProps: {
            style: {backgroundColor: '#228be6', color: '#fff', borderColor: '#228be6'},
            className: "backButton"
        },
        confirmProps: {color: 'red', className: "confirmButton"},
        groupProps: {
            style: {
                "-webkit-box-pack": "start",
                "justify-content": "center",
                marginBottom: 10,
                marginTop: -5
            }
        },
        labels: {confirm: 'Sil', cancel: "Geri dön"},
        onCancel: () => console.log('Cancel'),
        onConfirm: () => removeItem(id),
    });


    const setRefreshPassword = (values) => {

        setEditLoading(true);

        if (values.emailStatus || values.phoneStatus) {

            Connect().post(`member/reset/password/${values.id}`, {
                is_sms: values.phoneStatus,
                is_email: values.emailStatus,
                email: values.email,
                phone: values.phone
            }).then(({data}) => {

                setOpenPassword(false);

                SuccessViewing('Şifre Sıfırlama Bağlantısı Gönderildi', modals);

            }).catch((e) => ErrorViewing(e, modals)).finally(() => {
                setEditLoading(false);
            })


        } else {
            ErrorViewing({message: 'Email Adresi veya Telefon Numarasından en az 1 tanesi seçilmeli'}, modals);
        }

    }

    const getPassMember = (id) => {

        setPassId(id);

        Connect().get(`member/customer/form/history/${id}`).then(({data}) => {


            let result = data.result;

            setTotalPassPage(result.meta.lastPage);
            setPassList(result.data);


        }).catch((e) => ErrorViewing(e, modals));
    }


    const rows = users.map((element) => {


        return (
            <tr key={"table-" + element.id}>
                <td>
                    <div className={"table-name profile-name"}>

                        <Avatar src={element.image} radius="xl"/>


                        <b>{element.firstname} {element.lastname}
                            <Text color={"gray"} size={"sm"} style={{display: 'block'}}>{element.email}</Text>
                        </b>
                    </div>
                </td>
                <td>{element.username}</td>
                <td>
                    <div style={{position: 'relative', width: 200}}>
                        <TextInput size={"sm"} value={element.uuid ?? 'Kartı Yok'}/>

                        <CopyButton value={`${HIBRIT_CARD_PANEL}/id/${element.uuid}`}>
                            {({copied, copy}) => (
                                <Tooltip label={copied ? "Kopyalandı" : "Kopyala"}>
                                    <Button className={"copy-btn"} c size={"xs"} ml={5}
                                            color={copied ? 'teal' : 'violet'} onClick={copy}>
                                        <IoCopy/>
                                    </Button>
                                </Tooltip>
                            )}
                        </CopyButton>

                    </div>
                    </td>
                    <td>{element.phone_code ?? '--'}</td>
                    <td><Badge color={statusType(element.member_type)} className={"badge"}>{element.member_type}</Badge></td>
                    <td>
                        <Badge variant={"light"} className={"badge"} color={element.status ? 'teal' : 'gray'}>{element.status ? 'Aktif' : 'Pasif'}</Badge>
                    </td>
                    <td>
                        <Moment format={"DD/MM/YYYY HH:mm"}>{element.created_at}</Moment>
                    </td>

                    <td style={{color: gunFarkiHesapla(element.exp_date) > 0 ?  '#1cb676' : '#e51b1b'}}>
                        {gunFarkiHesapla(element.exp_date)} Gün
                    </td>


                    <td>
                        <Menu shadow="md" width={200}>
                            <Menu.Target>
                                <UnstyledButton><HiOutlineDotsHorizontal/></UnstyledButton>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>İşlemler</Menu.Label>
                                <Menu.Item onClick={() => {


                                    form.setValues({
                                        username: element.username,
                                        firstname: element.firstname,
                                        lastname: element.lastname,
                                        phone_code: element.phone_code,
                                        gender: element.gender,
                                        birth_date: new Date(element.birth_date),
                                        status: element.status,
                                        uuid: element.uuid,
                                        member_type: element.member_type
                                    })

                                    setUserId(element.id);
                                    setEditOpen(true);
                                }} icon={<FaRegEdit size={14}/>}>Düzenle</Menu.Item>
                                <Menu.Item onClick={() => {
                                    getMember(element.id);
                                    setOpenMenu(true);
                                }}
                                           icon={<TbPremiumRights style={{marginLeft: -1}} size={16}/>}>Premium
                                    Kodlar</Menu.Item>


                                <Menu.Item onClick={() => {
                                    getPassMember(element.id);
                                    setUserFormOpen(true);
                                }}
                                           icon={<TbClock style={{marginLeft: -1}} size={16}/>}>Müşteri Formu
                                    Geçmişi</Menu.Item>

                                <Menu.Item onClick={() => {


                                    rePasswordForm.setFieldValue('email', element.email);

                                    if (element.phone_code === null || element.phone_code === '' || element.phone_code === undefined) {
                                        rePasswordForm.setFieldValue('phone', '+90');
                                    } else {
                                        rePasswordForm.setFieldValue('phone', element.phone_code);
                                    }
                                    rePasswordForm.setFieldValue('id', element.id);

                                    setOpenPassword(true);
                                }}
                                           icon={<IoRefresh style={{marginLeft: -1}} size={16}/>}>Şifre Sıfırlama
                                    Gönder</Menu.Item>

                                <Menu.Item className={"remove-item"} color="red"
                                           onClick={() => openConfirmModal(element.id)}
                                           icon={<IoTrashOutline style={{marginLeft: -1}} size={16}/>}>Kullanıcıyı
                                    Sil</Menu.Item>

                            </Menu.Dropdown>
                        </Menu>
                    </td>

            </tr>
        )
    });


    const passRows = passList.map((element) => (
        <tr key={"code-" + element.id}>
            <td>#{element.id}</td>
            <td>{element.recipient_email}</td>
            <td>{element.ip_adress}</td>
            <td>{element.item_fullname}</td>
            <td>{element.item_email}</td>
            <td>{element.item_phone}</td>
            <td>
                <HoverCard width={250} shadow="md" position={"left"}>
                    <HoverCard.Target>
                        <ThemeIcon style={{cursor:'pointer'}} variant="light" color="violet">
                            <FaRegEnvelope/>
                        </ThemeIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                        <Text size="sm">
                            {element.item_message}
                        </Text>
                    </HoverCard.Dropdown>
                </HoverCard>

            </td>

            <td>
                <Moment format={"DD/MM/YYYY HH:mm"}>{element.created_at}</Moment>
            </td>




        </tr>
    ))


    return (<Container size={'100%'}>


        <Modal size={1100} padding={"xl"} opened={userFormOpen} onClose={() => {
            setUserFormOpen(false);
        }}
               title="Müşteri Formu Geçmişi" centered>



            <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                <Table className={"default-table"} striped highlightOnHover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Alıcı E-posta</th>
                        <th>Gönderen IP Adresi</th>
                        <th>Ad Soyad</th>
                        <th>E-posta</th>
                        <th>Telefon</th>
                        <th>Mesaj</th>
                        <th>Tarih</th>
                    </tr>
                    </thead>
                    <tbody>
                    {passRows}
                    </tbody>
                </Table>

            </div>


            <Center mt={25}>
                <Pagination size={"sm"} total={totalPassPage} page={activePassPage} color={"violet"}
                            onChange={(page) => {
                                setActivePassPage(page);
                                getPassMember(page, passId);
                            }}/>
            </Center>


        </Modal>


        <Modal size={600} padding={"xl"} opened={openPassword} onClose={() => {
            setOpenPassword(false);
            rePasswordForm.setValues({
                emailStatus: false,
                phoneStatus: false,
                id: '',
                email: '',
                phone: '+90'
            });
        }}
               title="Şifre Sıfırlama" centered>

            <form onSubmit={rePasswordForm.onSubmit((values) => setRefreshPassword(values))}>

                <Grid>


                    <Grid.Col lg={12}>
                        <Checkbox
                            label="Email'e Gönder(Pasif/Aktif)"
                            color="violet"
                            className={"checkbox-filled"}
                            {...rePasswordForm.getInputProps('emailStatus', {type: 'checkbox'})}
                        />
                        <Collapse mt={13} in={rePasswordForm.values.emailStatus}>
                            <span className={"label-title"}>Email*</span>
                            <TextInput
                                placeholder="Email"
                                size={"md"}
                                mt={10}
                                {...rePasswordForm.getInputProps('email')}
                            />
                        </Collapse>

                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <Checkbox
                            label="Sms Gönder(Pasif/Aktif)"
                            color="violet"
                            className={"checkbox-filled"}
                            {...rePasswordForm.getInputProps('phoneStatus', {type: 'checkbox'})}
                        />
                        <Collapse mt={13} in={rePasswordForm.values.phoneStatus}>
                            <span className={"label-title"}>Telefon Numarası*</span>
                            <div className="phone-input-form">
                                <PhoneInput
                                    specialLabel={""}
                                    placeholder={"Telefon Numarası"}
                                    countryCodeEditable={false}
                                    autoFormat={true}
                                    value={rePasswordForm.values.phone}
                                    onChange={phone => rePasswordForm.setFieldValue('phone', phone)}
                                />
                            </div>
                        </Collapse>

                    </Grid.Col>

                </Grid>

                <Button fullWidth loading={editLoading} className={"active-btn"}
                        mt={25}
                        type="submit">Şifre Sıfırlama Gönder</Button>
            </form>


        </Modal>

        <Drawer
            opened={openMenu}
            onClose={() => setOpenMenu(false)}
            title="Premium Kodlar"
            position={"right"}
            padding={"xl"}
            overlayProps={{opacity: 0.5, blur: 4}}
        >

            <Table className={"default-table"} striped highlightOnHover>
                <thead>
                <tr>
                    <th>
                        Kod
                    </th>
                    <th>
                        Durum
                    </th>
                    <th>
                        Tarih
                    </th>

                </tr>
                </thead>
                <tbody>
                {codeList.map((element, index) => (
                    <tr key={"code-" + element.id}>
                        <td>
                            <div className={"table-name profile-name"}>
                                {element.code}
                            </div>
                        </td>
                        <td>
                            <Badge variant={"light"} className={"badge"}
                                   color={element.status ? 'red' : 'teal'}>{element.status ? 'Kullanılmış' : 'Kullanılmamış'}</Badge>

                        </td>
                        <td>
                            <Moment format={"YYYY-MM-DD HH:mm"}>{element.created_at}</Moment>
                        </td>


                    </tr>
                ))}
                </tbody>
            </Table>


            <Button mt={40} loading={premiumLoading} fullWidth color={"violet"} className={"active-btn"}
                    onClick={() => setPremium()}>Premium Kod Oluştur</Button>


        </Drawer>


        <Drawer
            opened={editOpen}
            onClose={() => setEditOpen(false)}
            title="Üyelik Bilgileri"
            position={"right"}
            padding={"xl"}
            overlayProps={{opacity: 0.5, blur: 4}}
        >
            <form onSubmit={form.onSubmit((values) => _setUser(values))}>

                <Grid>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Kullanıcı Adı*</span>
                        <TextInput
                            placeholder="Kullanıcı Adı"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('username')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Ad*</span>
                        <TextInput
                            placeholder="Ad"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('firstname')}
                        />
                    </Grid.Col>
                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Soyad*</span>
                        <TextInput
                            placeholder="Soyad"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('lastname')}
                        />
                    </Grid.Col>
                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Telefon Numarası</span>
                        <TextInput
                            placeholder="Telefon Numarası"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('phone_code')}
                        />
                    </Grid.Col>
                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Cinsiyet*</span>
                        <Select
                            {...form.getInputProps('gender')}
                            data={[
                                {
                                    value: 'Male',
                                    label: 'Erkek'
                                },
                                {
                                    value: 'Female',
                                    label: 'Kadın'
                                },
                                {
                                    value: 'Unknown',
                                    label: 'Belirtilmemiş'
                                }
                            ]}/>

                    </Grid.Col>
                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Doğum Tarihi*</span>
                        <DateInput
                            placeholder="Doğum Tarihi"
                            size={"md"}
                            mt={10}
                            locale={"tr"}
                            {...form.getInputProps('birth_date')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>UUID*</span>
                        <TextInput
                            placeholder="UUID"
                            size={"md"}
                            mt={10}
                            {...form.getInputProps('uuid')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Üyelik Tipi*</span>
                        <Select
                            {...form.getInputProps('member_type')}
                            data={[
                                {
                                    value: 'Test',
                                    label: 'Test'
                                },
                                {
                                    value: 'Standart',
                                    label: 'Standart'
                                },
                                {
                                    value: 'Premium',
                                    label: 'Premium'
                                }
                            ]}/>

                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Durum*</span>
                        <Switch {...form.getInputProps('status', {type: 'checkbox'})}/>
                    </Grid.Col>


                </Grid>


                <Group mt={25} grow className={"bottom-head"}>

                    <Button loading={editLoading} className={"active-btn"}
                            type="submit">Düzenlemeyi Kaydet</Button>
                </Group>
            </form>

        </Drawer>


        <Grid>
            <Grid.Col lg={12} sx={{position:'relative'}}>

                <TextInput label={"Arama"} icon={<IoSearch style={{marginLeft: 5}} size={20}/>} mt={15}
                           className={"form-input"}
                           placeholder={"Link, Email, Kullanıcı Adı"}
                           onChange={(event) => {
                               getUsers(activePage, event.currentTarget.value);
                               setSearch(event.currentTarget.value);
                           }}
                />


                <Select  className={"border-none-select"} sx={{
                    width:200,
                    position:'absolute',
                    right:13,
                    top:52,

                }} value={searchType} onChange={(val) => {

                    if (val !== null) {
                        setSearchType(val);
                    } else {
                        setSearchType('');
                    }

                }} color={"violet"} placeholder={'Arama Tipi Seç'} size={"sm"} data={[
                    {
                        value: 'username',
                        label: 'Kullanıcı Adı'
                    },
                    {
                        value: 'email',
                        label: 'Email'
                    },
                    {
                        value: 'uuid',
                        label: 'Link'
                    }
                ]}/>

            </Grid.Col>


        </Grid>

        <div className="table-card">

            <div className="table-head">
                <Grid>
                    <Grid.Col lg={5}>
                        <Group>
                            <Title className={"table-title"} order={1}>Kullanıcılar</Title>
                        </Group>
                        <Text className={"default-table-text"}>Arama yapmak için Link, Email veya Kullanıcı Adı
                            girin.</Text>

                    </Grid.Col>

                    <Grid.Col lg={7}>


                        {/*
                                                sağ tarafa 2 tane selectbox olsun. 1.select box’da Tarih’e göre, Ad Soyad’a göre, Kalan Süre’ye göre yazsın. 2.selectbox itemleri: Sondan Başa (DESC), Baştan Sona (ASC)

                        */}

                        <Group position={"right"}>
                            <Select className={"mobile-w-100-input"} value={filterId} onChange={(val) => {
                                if (val !== null) {
                                    setFilterId(val);
                                    setFilter(val);
                                } else {
                                    setFilterId('');
                                    setFilter('');
                                }
                                getUsers(activePage, search, val, orderId, true);

                            }} placeholder={"Sıralama Seç"} clearable data={[
                                {
                                    value: 'created_at',
                                    label: "Tarih'e Göre Sırala"
                                },
                                {
                                    value: 'firstname',
                                    label: "Ad Soyad'a Göre Sırala"
                                },
                                {
                                    value: 'exp_date',
                                    label: "Kalan Süreye Göre Sırala"
                                }
                            ]}/>

                            <Select className={"mobile-w-100-input"} value={orderId} onChange={(val) => {

                                if (val !== null) {
                                    setOrderId(val);
                                    setOrder(val);
                                } else {
                                    setOrderId('');
                                    setOrder('');
                                }

                                getUsers(activePage, search, filterId, val, true);

                            }} color={"violet"} placeholder={'Sıralama Seç'} size={"sm"} data={[
                                {
                                    value: 'asc',
                                    label: 'Baştan Sona Sırala'
                                },
                                {
                                    value: 'desc',
                                    label: 'Sondan Başa Sırala'
                                }
                            ]}/>


                        </Group>

                    </Grid.Col>


                </Grid>
            </div>

            <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                <Table className={"default-table"} striped highlightOnHover>
                    <thead>
                    <tr>
                        <th>
                            Ad Soyad
                        </th>
                        <th>
                            Kullanıcı Adı
                        </th>
                        <th>Link</th>

                        <th>
                            Telefon
                        </th>
                        <th>
                            Üyelik Tipi
                        </th>
                        <th>
                            Durum
                        </th>
                        <th>Tarih</th>
                        <th>Kalan Süre</th>
                        <th>İşlemler</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>

            <div className="bottom-card">
                <Center>
                    <Pagination size={"sm"} total={totalPage} page={activePage} color={"violet"} onChange={(page) => {
                        setActivePage(page);
                        getUsers(page, search, filterId, orderId);
                    }}/>
                </Center>
            </div>


        </div>


    </Container>)

}

export default UserPage;
