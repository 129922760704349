import {Center, createStyles, Group, Paper, RingProgress, Text, ThemeIcon} from "@mantine/core";


const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    label: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
}));

export const HomeCard = ({item, key}) => {

    const {classes} = useStyles();


    return (
        <Paper withBorder radius="md" p="xs" key={key}>
            <Group>
               <ThemeIcon size={"xl"} color={item.color} variant={"light"}>
                   {item.icon}
               </ThemeIcon>

                <div>
                    <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
                        {item.title}
                    </Text>
                    <Text weight={700} size="xl">
                        {item.value}
                    </Text>
                </div>
            </Group>
        </Paper>
    );

}


export default HomeCard;
