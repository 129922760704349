import axios from 'axios';
import {getSession} from "./auth";
import {API_URL} from "./constants";

// connector
export default function (auth = true) {

    const token = getSession();

    return axios.create({
        baseURL: API_URL, // process.env.REACT_APP_API_URL,
        timeout: 30000,
        headers: auth ? {
            Authorization: 'Bearer ' + token
        } : {

        }
    });
}


