import Cookies from 'js-cookie'

export const setUser = (user) => {
    return Cookies.set('__admin', JSON.stringify(user));
}

export const getUser = () => {
    const user = Cookies.get('__admin');
    return JSON.parse(user !== undefined ? user : "{}");
}



export const setFilter = (id) => {
    return Cookies.set('__filter', id);
}


export const setOrder = (id) => {
    return Cookies.set('__order', id);
}


export const getFilter = () => {
    const id = Cookies.get('__filter');
    return id !== undefined ? id : ''
}



export const getOrder = () => {
    const id = Cookies.get('__order');
    return id !== undefined ? id : 'desc'
}

