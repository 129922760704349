import {getSession} from "./auth";
import {Navigate, Outlet} from "react-router-dom";


const PrivateRoute = () => {
    const token = getSession();

    return( token !== undefined ? <Outlet/> :  <Navigate to="/login"  />)

}

export default PrivateRoute;
