import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ModalsProvider } from '@mantine/modals';
import { store } from "./store";
import React, { useState } from "react";
import Content from "./lib/components/Content";
// pages
import Home from "./app/home";
import Login from "./app/login";
import PrivateRoute from "./lib/util/PrivateRoute";
import { Notifications } from "@mantine/notifications";
import { MantineProvider } from "@mantine/core";
import LinkAdd from "./app/link/add";
import UserPage from "./app/users";
import NoticePage from "./app/notice";
import PricePage from "./app/price";
import SalePage from "./app/sales";
import { PacketsPage } from "./app/packets";
import EmailPage from "./app/emails";
import { ExtendPage } from "./app/extend";
import BulkPage from "./app/bulk";

require('dayjs/locale/tr');


function App() {

  const [userInfo, setUserInfo] = useState({});



  return (

      <Provider store={store}>
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <Notifications/>
          <ModalsProvider>
            <Content user={userInfo}>
              <Routes>
                <Route path={"/login"} element={<Login/>}/>

                <Route element={<PrivateRoute/>}>
                  <Route path={"/sales"} element={<SalePage/>}/>
                  <Route path={"/notices"} element={<NoticePage/>}/>
                  <Route path={"/emails"} element={<EmailPage/>}/>
                  <Route path={"/extend-time"} element={<ExtendPage/>}/>
                  <Route path={"/bulk/send"} element={<BulkPage/>}/>
                  <Route path={"/prices"} element={<PricePage/>}/>
                  <Route path={"/packets"} element={<PacketsPage/>}/>
                  <Route path={"/users"} element={<UserPage/>}/>
                  <Route path={"/link/add"} element={<LinkAdd/>}/>
                  <Route path={"/"} index element={<Home/>}/>
                </Route>
              </Routes>
            </Content>
          </ModalsProvider>
        </MantineProvider>
      </Provider>


  );
}

export default App;
