import {useDocumentTitle} from "@mantine/hooks";
import React, {forwardRef, useEffect, useState} from "react";
import {
    Alert,
    Badge,
    Button,
    Center,
    Checkbox,
    Collapse,
    Container,
    CopyButton,
    Grid,
    Group,
    List, Loader,
    LoadingOverlay,
    Modal,
    NumberInput,
    Pagination,
    Select,
    Table,
    Tabs,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    Tooltip,
    UnstyledButton
} from "@mantine/core";
import {
    IoAdd,
    IoCheckmark,
    IoClose,
    IoCopyOutline,
    IoInformationCircle,
    IoLink,
    IoList,
    IoQrCode, IoRefresh,
    IoSend,
    IoWarning
} from "react-icons/io5";
import Moment from 'react-moment';
import {useForm} from "@mantine/form";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {SuccessViewing} from "../../lib/util/SuccessViewing";
import {Link} from "react-router-dom";
import {HIBRIT_CARD_PANEL} from "../../lib/util/constants";
import PhoneInput from "react-phone-input-2";
import QRCode from "react-qr-code";
import {toPng} from 'html-to-image';
import download from 'downloadjs';


export const LinkAdd = () => {


    useDocumentTitle('HibritCard - Link Üret');


    const [pageLoading, setPageLoading] = useState(true);
    const [opened, setOpened] = useState(false);
    const [oneOpened, setOneOpened] = useState(false);
    const [mailOpened, setMailOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [addOneLoading, setAddOneLoading] = useState(false);
    const [openMemberType, setOpenMemberType] = useState(true);
    const [userItem, setUserItem] = useState({});
    const [users, setUsers] = useState([]);
    const [userList, setUserList] = useState([]);
    const [mailList, setMailList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchValue, onSearchChange] = useState('');
    const [codeList, setCodeList] = useState([]);
    const modals = useModals();
    const [uuid, setUuid] = useState('');
    const [resetId,setResetId] = useState('');


    const form = useForm({
        initialValues: {
            type: 'Standart',
            count: 1
        },

        validate: {
            type: (value) => (value !== '' ? null : 'Link Tipi seçmelisiniz'),
            count: (value) => ((value > 0 && value < 21) ? null : 'Geçersiz sayı'),
        },
    });


    const oneForm = useForm({
        initialValues: {
            type: 'Standart',
            user: '',
            email: '',
            phone: '+90',
            userStatus: false
        },

        validate: {
            type: (value) => (value !== '' ? null : 'Link Tipi seçmelisiniz'),
            /*    email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
            phone: (value) => (value !== '' ? null : 'Telefon Numarası girmelisiniz'),*/
        },
    });

    const mailForm = useForm({
        initialValues: {
            member_id: '',
            code: '',
            phone_text: '',
            email_text: '',
            sms: false,
            email: false,
        },


        validate: {
            code: (value) => (value !== '' ? null : 'Kod girmelisiniz'),

        },
    });


    useEffect(() => {

        getLink();
        getUsers();


    }, []);


    const getLink = (page = 1) => {

        Connect().get(`member/link/list?page=${page}`).then(({data}) => {


            setTotalPage(data.result.meta.lastPage);
            setUsers(data.result.data);


        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setPageLoading(false);
        });

    }


    const setLink = (values) => {

        setAddLoading(true);

        Connect().post('member/create/link', {
            member_type: values.type,
            amount: values.count
        }).then(({data}) => {

            setOpened(false);

            SuccessViewing('Link Üretildi', modals);

            getLink(activePage);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setAddLoading(false);
        });
    }

    const getUsers = () => {
        Connect().get('member/get/combo/list').then(({data}) => {


            let _data = [];

            data.result.map((item) => _data.push({
                value: item.id,
                label: item.email,
                fullname: item.firstname + ' ' + item.lastname
            }))

            setUserList(_data);


        }).catch((e) => ErrorViewing(e, modals));
    }


    const setCreateMember = (values) => {

        setAddOneLoading(true);

        let type;


        if (values.user !== '') {
            if (values.user !== null) {
                type = 'Shadow';

            } else {
                type = values.type;
            }

        } else {
            type = values.type;
        }


        Connect().post('member/create/link/only', {
            member_type: type,
            email: values.email,
            phone: values.phone,
            member_id: values.user !== '' ? values.user : null
        }).then(({data}) => {


            setOneOpened(false);

            SuccessViewing('Tekli Link Üretildi', modals);

            oneForm.setValues({
                type: 'Standart',
                user: '',
                email: '',
                phone: '+90',
                userStatus: false
            });

            getLink(activePage);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => {
            setAddOneLoading(false);
        });

    }


    const downloadQRCode = async (element) => {


        setUuid(element.uuid);

        const qrCodeElement = document.getElementById(`qrcode-0`);

        try {
            const dataUrl = await toPng(qrCodeElement);
            download(dataUrl, `${element?.uuid ?? `user-`}.png`);
        } catch (error) {
            console.error('QR Kodu indirme hatası:', error);
        }
    };

    const getCodes = (id) => {

        Connect().get(`member/link/history/${id}`).then(({data}) => {

            setMailList(data.result);

        }).catch((e) => ErrorViewing(e, modals));

    }

    const setCodeItem = (values) => {


        if (values.sms || values.email) {

            setMailLoading(true);

            Connect().post(`member/link/send/${values.member_id}`, {
                is_sms: values.sms,
                is_email: values.email,
                code: values.code,
                email: values.email_text,
                phone: values.phone_text
            }).then(({data}) => {

                getLink(activePage);

                SuccessViewing('Kod Gönderildi', modals);

                setMailOpened(false);


            }).catch((e) => ErrorViewing(e, modals)).finally(() => setMailLoading(false));

        } else {

            ErrorViewing({message: 'Sms veya mail adresinzden en az 1 tanesi seçmelisiniz'}, modals);

        }


    }


    const setResetCode = (id) => {

        setResetId(id);

        Connect().get(`member/link/reset/code/${id}`).then(({data}) =>{

            getLink(activePage);

            SuccessViewing('Kod Sıfırlandı', modals);

        }).catch((e) => ErrorViewing(e,modals)).finally(() =>{
           setResetId('');
        });

    }


    const SelectItem = forwardRef(
        ({image, label, fullname, ...others}, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <div>
                        <Text size="sm">{fullname}</Text>
                        <Text size="xs" opacity={0.65}>
                            {label}
                        </Text>
                    </div>
                </Group>
            </div>
        )
    );


    const rows = users.map((element, index) => {


        return (
            <tr key={"table-" + element.id}>
                <td>
                    #{element.id}
                </td>
                <td>
                    <div style={{float: 'left'}}>
                        {element.uuid}
                    </div>
                    <CopyButton value={`${HIBRIT_CARD_PANEL}/id/${element.uuid}`}>
                        {({copied, copy}) => (
                            <UnstyledButton onClick={copy}>
                                {copied ? <IoCheckmark size={15} style={{
                                    color: '#11c253',
                                    float: 'left',
                                    marginTop: 3,
                                    marginLeft: 4
                                }}/> : <IoCopyOutline size={15} style={{
                                    color: '#7B50F4',
                                    float: 'left',
                                    marginTop: 3,
                                    marginLeft: 4
                                }}/>}
                            </UnstyledButton>
                        )}

                    </CopyButton>
                </td>
                <td>{element.verification_code}</td>
                <td>
                    {element.status ? <IoCheckmark size={21} color={"#11c253"}/> : <IoClose size={21} color={"red"}/>}
                </td>
                <td>
                    <Badge variant={"light"} className={"badge"}
                           color={element.member_type === "Standart" ? 'gray' : 'violet'}>{element.member_type}</Badge>
                </td>

                <td>
                    {element?.member &&
                        <div className={"d-inline-block"}>
                            <Link className={"btn-item"} target={"_blank"}
                                  to={`${HIBRIT_CARD_PANEL}/id/${element.member.uuid}`}>
                                {element.member.firstname + ' ' + element.member.lastname} <IoLink
                                style={{float: 'right', marginLeft: 5, marginTop: 1}} color={"#7B50F4"} size={18}/>
                            </Link>
                        </div>
                    }

                </td>
                <td><Moment format={"DD/MM/YYYY HH:mm"}>{element.created_at}</Moment></td>
                <td><Moment format={"DD/MM/YYYY HH:mm"}>{element.updated_at}</Moment></td>
                <td>

                    {element.MemberLinkSendHistory.length > 0 &&
                        <Moment format={"DD/MM/YYYY HH:mm"}>{element.MemberLinkSendHistory[0].created_at}</Moment>
                    }

                    {element.MemberLinkSendHistory.length === 0 &&
                        <Badge className={"badge"} color={"red"} style={{textTransform: 'initial'}}>Hiç
                            gönderilmemiş</Badge>
                    }

                </td>

                <td>
                    <Tooltip
                        label="QR Kod İndir"
                        color="violet"
                        withArrow
                    >

                        <ThemeIcon onClick={() => downloadQRCode(element)} style={{cursor: 'pointer'}} variant={"light"}
                                   color={"violet"}>
                            <IoQrCode size={14}/>
                        </ThemeIcon>
                    </Tooltip>


                    <Tooltip
                        label="Kodu SMS/Mail Gönder"
                        color="teal"
                        withArrow
                    >

                        <ThemeIcon ml={6} onClick={() => {
                            getCodes(element.id);
                            setMailOpened(true);
                            mailForm.setValues({
                                code: element.verification_code,
                                phone_text: element?.phone,
                                email_text: element?.member?.email ?? '',
                                member_id: element.id,
                                sms: false,
                                email: false,
                            });

                        }} style={{cursor: 'pointer'}}
                                   variant={"light"} color={"teal"}>
                            <IoSend size={14}/>
                        </ThemeIcon>
                    </Tooltip>

                    <Tooltip
                        label="Kodu Sıfırla"
                        color="red"
                        withArrow
                        hidden={element.status}
                    >

                        <ThemeIcon ml={6} onClick={() => {

                            if(!element.status){
                                setResetCode(element.id);
                            }

                        }} style={{cursor: !element.status ? 'pointer' : 'no-drop'}}
                                   variant={"light"} color={!element.status ? "red" : "gray"}>

                            {element.id === resetId ?    <Loader size={14} color={"red"}/> :   <IoRefresh size={14}/> }

                        </ThemeIcon>
                    </Tooltip>

                </td>
            </tr>
        )
    });

    const mailRows = mailList.map((element, index) => {


        return (
            <tr key={"mail-" + element.id}>
                <td>{element.id}</td>
                <td>{element.is_sms ? <IoCheckmark size={17} style={{color: '#2dd72f'}}/> :
                    <IoClose size={17} style={{color: '#ea3636'}}/>} </td>
                <td>{element.is_email ? <IoCheckmark size={17} style={{color: '#2dd72f'}}/> :
                    <IoClose size={17} style={{color: '#ea3636'}}/>} </td>
                <td>{element.code}</td>
                <td>{element.phone}</td>
                <td>{element.email}</td>

                <td><Moment format={"YYYY-MM-DD HH:mm"}>{element.created_at}</Moment></td>
            </tr>
        )
    });


    useEffect(() => {


        if (oneForm.values.user !== '') {
            if (oneForm.values.user === null) {
                setOpenMemberType(true);
            } else {
                setOpenMemberType(false);
            }
        } else {
            setOpenMemberType(true);

        }

    }, [oneForm.values.user]);


    return (<Container size={'100%'}>


        <Modal
            opened={opened}
            centered
            title={"Link Ekle"}
            size={640}
            radius={6}
            padding={"xl"}
            onClose={() => {
                setOpened(false);
            }}>


            <form onSubmit={form.onSubmit((values) => setLink(values))}>

                <Grid>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Link Tipi*</span>
                        <Select
                            placeholder="Seç"
                            data={[
                                {value: 'Standart', label: 'Standart'},
                                {value: 'Premium', label: 'Premium'},
                            ]}
                            {...form.getInputProps('type')}
                        />
                    </Grid.Col>
                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Sayı (Max: 20)*</span>
                        <NumberInput
                            placeholder="Başlık Gir"
                            size={"md"}
                            min={1}
                            max={20}
                            mt={10}
                            {...form.getInputProps('count')}
                        />
                    </Grid.Col>
                </Grid>


                <Group mt={25} grow className={"bottom-head"}>

                    <Button loading={addLoading} className={"active-btn"}
                            type="submit">Link Üret</Button>
                </Group>
            </form>

        </Modal>


        <Modal
            opened={oneOpened}
            centered
            title={"Tekli Link Ekle"}
            size={660}
            radius={6}
            padding={"xl"}
            onClose={() => {
                setOneOpened(false);
                oneForm.setValues({
                    type: 'Standart',
                    user: '',
                    email: '',
                    phone: '+90',
                    userStatus: false
                })
            }}>

            <Alert mb={15} icon={<IoWarning size="1rem"/>} color="red">
                Eğer Kullanıcı Link’e bağlanmışsa, Link Tipi seçemezsiniz
            </Alert>

            <form onSubmit={oneForm.onSubmit((values) => setCreateMember(values))}>

                <Grid>

                    <Grid.Col lg={12}>
                        <span
                            className={"label-title"}>Kullanıcıyı Bağla  <b><small>(Kullanıcı seçmek zorunlu değildir. Boş bıraktığınızda kullanıcı kendi hesabını oluşturabilir)</small></b></span>
                        <Select
                            placeholder="Seç"
                            data={userList}
                            itemComponent={SelectItem}
                            onSearchChange={onSearchChange}
                            searchValue={searchValue}
                            searchable
                            clearable
                            nothingFound="Sonuç bulunamadı!"
                            filter={(value, item) =>
                                item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                item.fullname.toLowerCase().includes(value.toLowerCase().trim())
                            }
                            {...oneForm.getInputProps('user')}
                        />

                    </Grid.Col>

                    <Collapse style={{width: '100%'}} in={openMemberType}>
                        <Grid.Col lg={12}>
                            <span className={"label-title"}>Link Tipi*</span>
                            <Select
                                placeholder="Seç"
                                data={[
                                    {value: 'Standart', label: 'Standart'},
                                    {value: 'Premium', label: 'Premium'},
                                ]}
                                {...oneForm.getInputProps('type')}
                            />
                        </Grid.Col>
                    </Collapse>


                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Email Adresi</span>
                        <TextInput
                            placeholder="Email"
                            className={"form-input"}
                            {...oneForm.getInputProps('email')}
                        />
                    </Grid.Col>

                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Telefon Numarası</span>

                        <div className="phone-input-form">
                            <PhoneInput
                                specialLabel={""}
                                placeholder={"Telefon Numarası"}
                                countryCodeEditable={false}
                                autoFormat={true}
                                value={oneForm.values.phone}
                                onChange={phone => oneForm.setFieldValue('phone', phone)}
                            />
                        </div>

                    </Grid.Col>


                    {/*
                    <Grid.Col lg={12}>
                        <span className={"label-title"}>Kullanıcıya Bağla (Pasif/Aktif)</span>

                        <Switch
                            size={"md"}
                            color="violet"
                            {...oneForm.getInputProps('userStatus',{type:'checkbox'})}
                        />
                    </Grid.Col>
                    */}


                </Grid>


                <Group mt={25} grow className={"bottom-head"}>

                    <Button loading={addOneLoading} className={"active-btn"}
                            type="submit">Tekli Link Üret</Button>
                </Group>
            </form>

        </Modal>


        <Modal
            opened={mailOpened}
            centered
            title={"Kodu SMS/Mail Gönder"}
            size={800}
            radius={6}
            padding={"xl"}
            onClose={() => {
                setMailOpened(false);
            }}>

            <Tabs color="violet" defaultValue="tab1">
                <Tabs.List>
                    <Tabs.Tab value="tab1" icon={<IoList size="0.8rem"/>}>Gönderim Geçmişi</Tabs.Tab>
                    <Tabs.Tab value="tab2" icon={<IoAdd size="0.8rem"/>}>Yeni İleti</Tabs.Tab>

                </Tabs.List>

                <Tabs.Panel value="tab1" pt="xs">
                    <Table striped>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>SMS</th>
                            <th>Email</th>
                            <th>Kod</th>
                            <th>Telefon</th>
                            <th>Email Adresi</th>
                            <th>Tarih</th>
                        </tr>
                        </thead>
                        <tbody>{mailRows}</tbody>
                    </Table>
                </Tabs.Panel>

                <Tabs.Panel value="tab2" pt="xs">

                    <form onSubmit={mailForm.onSubmit((values) => setCodeItem(values))}>

                        <Grid mt={10}>

                            <List mb={5} size={"sm"} pl={10} spacing={16} listStyleType={"none"}>
                                <List.Item>Kod: <Badge style={{textTransform: 'initial'}}
                                                       color={"violet"}>{mailForm.values.code}</Badge></List.Item>
                            </List>

                            <Grid.Col lg={12}>
                                <span className={"label-title"}>Telefon Numarası</span>
                                <div className="phone-input-form">
                                    <PhoneInput
                                        specialLabel={""}
                                        placeholder={"Telefon Numarası"}
                                        countryCodeEditable={false}
                                        autoFormat={true}
                                        value={mailForm.values.phone_text}
                                        onChange={phone => mailForm.setFieldValue('phone_text', phone)}
                                    />
                                </div>
                            </Grid.Col>

                            <Grid.Col lg={12}>
                                <span className={"label-title"}>Email Adresi</span>
                                <TextInput
                                    placeholder="Email"
                                    className={"form-input"}
                                    {...mailForm.getInputProps('email_text')}
                                />
                            </Grid.Col>

                            <Grid.Col lg={12} mt={14}>
                                <Group spacing={"xl"} className={"checkbox-filled"}>
                                    <div>
                                        <Checkbox
                                            label={"SMS"}
                                            color={"violet"}
                                            {...mailForm.getInputProps('sms', {type: 'checkbox'})}
                                        />
                                    </div>

                                    <div>
                                        <Checkbox
                                            label={"E-posta"}
                                            color={"violet"}
                                            {...mailForm.getInputProps('email', {type: 'checkbox'})}
                                        />
                                    </div>
                                </Group>
                            </Grid.Col>


                        </Grid>


                        <Group mt={25} grow className={"bottom-head"}>

                            <Button loading={mailLoading} className={"active-btn"}
                                    type="submit">Kodu Gönder</Button>
                        </Group>
                    </form>

                </Tabs.Panel>
            </Tabs>


        </Modal>


        <div style={{opacity: 0, height: 0, width: 0}}>
            <QRCode size={400} id={`qrcode-0`} value={`${HIBRIT_CARD_PANEL}/id/${uuid}`}/>
        </div>


        <Alert color={"teal"} icon={<IoInformationCircle style={{marginRight: -5}}/>}>
            <b>Shadow</b> kullanıcı tipi, Önceden açılmış hesaba bağlı ortak kartın olduğunu temsil eder. Yani
            üretilen 3 kart varsa, o hesaba bağlıysa, 3'üde o hesaba gider. Hesabın Paket tipini, ilk üretilen kart
            belirler
        </Alert>

        <Grid mb={20}>
            <Grid.Col lg={12}>
                <div className="table-card">

                    <div className="table-head">
                        <Grid>
                            <Grid.Col lg={8}>
                                <Group>
                                    <Title className={"table-title"} order={1}>Link Üret</Title>
                                </Group>
                                <Text className={"default-table-text"}>Üretilen linkler</Text>
                            </Grid.Col>
                            <Grid.Col lg={4}>
                                <Group position={'right'}>
                                    <Button onClick={() => setOpened(true)} color={"violet"}>Çoklu Link Üret</Button>
                                    <Button onClick={() => setOneOpened(true)} color={"teal"}>Tekli Link Üret</Button>
                                </Group>
                            </Grid.Col>

                        </Grid>
                    </div>


                    <div className="scroll-table min-scroll-table" style={{position: 'relative'}}>
                        <LoadingOverlay visible={pageLoading} overlayBlur={2}/>

                        <Table className={"default-table"} striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Link</th>
                                <th>Kod</th>
                                <th>Kod Onayı</th>
                                <th>Kullanıcı Tipi</th>
                                <th>Bağlı Olduğu Hesap</th>
                                <th>Oluşturma Tarihi</th>
                                <th>Kullanım Tarihi</th>
                                <th>Son Sms/Email Gönderim Tarihi</th>
                                <th>İşlemler</th>

                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </div>

                    <div className="bottom-card">
                        <Center>
                            <Pagination size={"sm"} total={totalPage} page={activePage} color={"violet"}
                                        onChange={(page) => {
                                            setActivePage(page);
                                            getLink(page);
                                        }}/>
                        </Center>
                    </div>


                </div>

            </Grid.Col>
        </Grid>

    </Container>)

}

export default LinkAdd;
